import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData,
} from "../../components/DataTableNew";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import type from "../../constant/type";
import Divider from "../../components/Divider";
import { createUserPopup, fetchCustomer } from "./Customer";
import api from "../../constant/api";
import AlertBox from "../../components/AlertBox";
function CreateCustomer() {
  const dispatch = useDispatch();
  const phoneRegExp = /^[0]?[0]\d{9}$/;
  const defaultState = {
    notify: {
      msg: "",
      code: "0",
      show: true,
    },
  };
  const [state, setState] = useState(defaultState);

  const formik = useFormik({
    initialValues: {
      short_code: "",
      name: "",
      position: "",
      company: "",
      address: "",
      city: "",
      contact1: "",
      contact2: "",
      email: "",
      attn1_name: "",
      attn1_contact: "",
      attn1_email: "",
      attn2_name: "",
      attn2_contact: "",
      attn2_email: "",
    },
    validationSchema: yup.object({
      short_code: yup.string().required("This Feild is Required."),
      name: yup.string(),
      position: yup.string(),
      company: yup.string(),
      address: yup.string(),
      city: yup.string(),
      contact1: yup.string().matches(phoneRegExp),
      contact2: yup.string().matches(phoneRegExp),
      email: yup.string().email("Invalid Email Address."),
      attn1_name: yup.string(),
      attn1_contact: yup.string().matches(phoneRegExp),
      attn1_email: yup.string().email("Invalid Email Address."),
      attn2_name: yup.string(),
      attn2_contact: yup.string().matches(phoneRegExp),
      attn2_email: yup.string().email("Invalid Email Address."),
    }),
    onSubmit: (formData) => {
      saveCustomerData(formData, dispatch, formik);
    },
  });
  return (
    <DataTable>
      <DataTableBody>
        <div class="card  bg-transparent border-0">
          <div class="card-body">
            <div class="card-form">
              <form
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                className="row"
                id="createCustomerForm"
              >
                <div className="col-md-12 form-row mb-3">
                  <AlertBox
                    show={state.notify.show}
                    msg={state.notify.msg}
                    status={state.notify.code}
                    eve={(e) => {
                      setState(
                        Object.assign({}, state, {
                          notify: {
                            ...state.notify,
                            show: false,
                          },
                        })
                      );
                      console.log("work");
                    }}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="short_code"
                    label="Short Code"
                    val={formik.values.short_code}
                    eve={formik.handleChange}
                    error={formik.errors.short_code}
                  />
                </div>

                <div class="col-md-8 form-row">
                  <TextItem
                    name="company"
                    label="Company"
                    val={formik.values.company}
                    eve={formik.handleChange}
                    error={formik.errors.company}
                  />
                </div>
                <div class="col-md-4 form-row">
                  <TextItem
                    name="position"
                    label="Position"
                    val={formik.values.position}
                    eve={formik.handleChange}
                    error={formik.errors.position}
                  />
                </div>
                <div class="col-md-8 form-row">
                  <TextItem
                    name="name"
                    label="Customer Name"
                    val={formik.values.name}
                    eve={formik.handleChange}
                    error={formik.errors.name}
                  />
                </div>

                <div class="col-md-12 form-row">
                  <TextItem
                    name="address"
                    label="Address"
                    val={formik.values.address}
                    eve={formik.handleChange}
                    error={formik.errors.address}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="city"
                    label="City"
                    val={formik.values.city}
                    eve={formik.handleChange}
                    error={formik.errors.city}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="email"
                    label="Email"
                    val={formik.values.email}
                    eve={formik.handleChange}
                    error={formik.errors.email}
                  />
                </div>
                <div class="col-md-4 form-row">
                  <TextItem
                    name="contact1"
                    label="Contact 1"
                    val={formik.values.contact1}
                    eve={formik.handleChange}
                    error={formik.errors.contact1}
                  />
                </div>
                <div class="col-md-4 form-row">
                  <TextItem
                    name="contact2"
                    label="Contact 2"
                    val={formik.values.contact2}
                    eve={formik.handleChange}
                    error={formik.errors.contact2}
                  />
                </div>

                <Divider />

                <div class="col-md-4 form-row">
                  <TextItem
                    name="attn1_name"
                    label="Attn1 Name"
                    val={formik.values.attn1_name}
                    eve={formik.handleChange}
                    error={formik.errors.attn1_name}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="attn1_contact"
                    label="Attn1 Contact"
                    val={formik.values.attn1_contact}
                    eve={formik.handleChange}
                    error={formik.errors.attn1_contact}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="attn1_email"
                    label="Attn1 Email"
                    val={formik.values.attn1_email}
                    eve={formik.handleChange}
                    error={formik.errors.attn1_email}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="attn2_name"
                    label="Attn2 Name"
                    val={formik.values.attn2_name}
                    eve={formik.handleChange}
                    error={formik.errors.attn2_name}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="attn2_contact"
                    label="Attn2 Contact"
                    val={formik.values.attn2_contact}
                    eve={formik.handleChange}
                    error={formik.errors.attn2_contact}
                  />
                </div>

                <div class="col-md-4 form-row">
                  <TextItem
                    name="attn2_email"
                    label="Attn2 Email"
                    val={formik.values.attn2_email}
                    eve={formik.handleChange}
                    error={formik.errors.attn2_email}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </DataTableBody>

      <DataTableFooter>
        <div className="card-body">
          <div className="d-flex justify-content-between m-3">
            <button
              className="btn btn-primary invoice-action-btn m-0"
              type="submit"
              form="createCustomerForm"
              data-name="new"
            >
              <span>New</span>
            </button>
            <button
              className="btn btn-success invoice-action-btn m-0"
              type="submit"
              form="createCustomerForm"
            >
              <span>Save</span>
            </button>
          </div>
        </div>
      </DataTableFooter>
    </DataTable>
  );
}

const TextItem = (props) => {
  const { label, name, val, eve, error, readOnly } = props;
  return (
    <>
      <label className="mb-2 text-white">{label}</label>
      <input
        type="text"
        name={name}
        data-name={name}
        value={val}
        class="form-control rawinput mb-1 custom-input"
        onChange={eve}
        readOnly={readOnly}
      />

      <div class="form-text mb-3 text-danger text-capitalize">
        {error ? error : ""}
      </div>
    </>
  );
};

TextItem.defaultProps = {
  readOnly: false,
};

function saveCustomerData(formData, dispatch, formik) {
  const {
    short_code,
    position,
    name,
    email,
    company,
    city,
    address,
    contact1,
    contact2,
    attn1_name,
    attn1_email,
    attn1_contact,
    attn2_name,
    attn2_email,
    attn2_contact,
  } = formData;

  const finalApiData = {
    short_code: short_code,
    position: position,
    name: name,
    email: email,
    company: company,
    city: city,
    address: address,
    contact1: contact1,
    contact2: contact2,
    attn: {
      one: {
        name: attn1_name,
        contact: attn1_contact,
        email: attn1_email,
      },
      two: {
        name: attn2_name,
        contact: attn2_email,
        email: attn2_contact,
      },
    },
  };

  axios.post(api.saveCustomer, finalApiData).then((res) => {
    if (res.data.status) {
      fetchCustomer(dispatch);
      formik.resetForm();
      createUserPopup(dispatch);
    }
  });
}

export default CreateCustomer;
