import type from "../../../../constant/type";
import { fetchProductWhenType } from "./fetchProductWhenType";
// STORE INVOICE ITEMS
const onChnageInvoiceItem = (e, dispatch) => {
  let name = e.target.dataset.type;
  let key = e.target.dataset.key;
  let currentValue = e.target.value ? e.target.value : "";

  console.log(name);
  let validationState = true;
  let numberRegex = /^[0-9]+$/;
  let floatRegex = /^[+-]?(?:\d*\.)?\d+$/;

  switch (name) {
    case "qty":
      if (currentValue) {
        if (numberRegex.test(currentValue)) {
          currentValue =
            parseInt(currentValue) > 0 ? parseInt(currentValue) : 1;
        } else {
          validationState = false;
        }
      } else {
        currentValue = 1;
      }
      break;
    case "unit":
      if (currentValue) {
        if (floatRegex.test(currentValue)) {
          validationState = true;
          currentValue = parseFloat(currentValue);
        } else {
          validationState = false;
        }
      } else {
        currentValue = 0;
      }
      break;
    case "discount":
      if (currentValue) {
        if (floatRegex.test(currentValue)) {
          validationState = true;
          currentValue = parseFloat(currentValue);
        } else {
          validationState = false;
        }
      } else {
        currentValue = 0;
      }
      break;
    case "lineTotal":
      if (currentValue) {
        if (floatRegex.test(currentValue)) {
          validationState = true;
          currentValue = parseFloat(currentValue);
        } else {
          validationState = false;
        }
      } else {
        currentValue = 0;
      }
      break;
    case "name":
      console.log(e);
      if (currentValue) {
        fetchProductWhenType(currentValue, dispatch, key);
      } else {
        dispatch({
          type: type.SHOW_SUGGEST,
          payload: {
            key: key,
            data: {
              suggest: false,
            },
          },
        });
      }

      break;
    default:
      break;
  }

  if (validationState) {
    dispatch({
      type: type.STORE_INVOICE_DATA,
      payload: {
        key: "item",
        data: {
          name: name,
          key: key,
          value: currentValue,
        },
      },
    });

    dispatch({
      type: type.CALCULATE_INVOICE_TOTAL,
    });
  } else {
    console.log("validation error.");
  }
};

export { onChnageInvoiceItem };
