const api = {
  getIvoice: "https://api.invoice.royaltech.lk/api/invoice",
  changePaymentStatus:
    "https://api.invoice.royaltech.lk/api/invoice/payment/status/change",
  getInvoiceInfo:
    "https://api.invoice.royaltech.lk/api/invoice/info?invoice_no=",
  deleteInvoice: "https://api.invoice.royaltech.lk/api/invoice/delete",
  saveInvoice: "https://api.invoice.royaltech.lk/api/invoice/save",
  authLogin: "https://api.invoice.royaltech.lk/api/auth/login",
  getLastInvoiceNo: "https://api.invoice.royaltech.lk/api/invoice/number",
  saveProduct: "https://api.invoice.royaltech.lk/api/product/save",
  getProduct: "https://api.invoice.royaltech.lk/api/product",
  getMainCategory: "https://api.invoice.royaltech.lk/api/main-category",
  getSubCategory: "https://api.invoice.royaltech.lk/api/sub-category",
  saveCustomer: "https://api.invoice.royaltech.lk/api/customer/save",
  getCustomer: "https://api.invoice.royaltech.lk/api/customer",
  findCustomer: "https://api.invoice.royaltech.lk/api/customer/find",
  findProduct: "https://api.invoice.royaltech.lk/api/product/find",

  createCategory: "https://api.invoice.royaltech.lk/api/main-category/create",
  createSubCategory: "https://api.invoice.royaltech.lk/api/sub-category/create",
  getInvoiceCustomerCode:
    "https://api.invoice.royaltech.lk/api/invoice/customer/code",
};

/*
const api = {
  getIvoice: "http://127.0.0.1:8000/api/invoice",
  changePaymentStatus:
    "http://127.0.0.1:8000/api/invoice/payment/status/change",
  getInvoiceInfo: "http://127.0.0.1:8000/api/invoice/info?invoice_no=",
  deleteInvoice: "http://127.0.0.1:8000/api/invoice/delete",
  saveInvoice: "http://127.0.0.1:8000/api/invoice/save",
  authLogin: "http://127.0.0.1:8000/api/auth/login",
  getLastInvoiceNo: "http://127.0.0.1:8000/api/invoice/number",
  saveProduct: "http://127.0.0.1:8000/api/product/save",
  getProduct: "http://127.0.0.1:8000/api/product",
  getMainCategory: "http://127.0.0.1:8000/api/main-category",
  getSubCategory: "http://127.0.0.1:8000/api/sub-category",
  saveCustomer: "http://127.0.0.1:8000/api/customer/save",
  getCustomer: "http://127.0.0.1:8000/api/customer",
  findCustomer: "http://127.0.0.1:8000/api/customer/find",
  findProduct: "http://127.0.0.1:8000/api/product/find",

  createCategory: "http://127.0.0.1:8000/api/main-category/create",
  createSubCategory: "http://127.0.0.1:8000/api/sub-category/create",

  getInvoiceCustomerCode: "http://127.0.0.1:8000/api/invoice/customer/code",
};
*/
export default api;
