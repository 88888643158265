import type from "../../../../constant/type";

function setFIndProductData(e, dispatch, findedProduct, feild) {
  const pos = e.target.dataset.key;
  const find = findedProduct[pos];
  const actionFeild = feild;

  dispatch({
    type: type.SET_DEFAULT_INVOICE_PRODUCT,
    payload: {
      key: actionFeild,
      data: {
        name: find.name,
        discount: find.discount,
        unit: find.sell_amount,
        qty: 1,
      },
    },
  });

  dispatch({
    type: type.SHOW_SUGGEST,
    payload: {
      key: actionFeild,
      data: {
        suggest: false,
      },
    },
  });

  dispatch({
    type: type.CALCULATE_INVOICE_TOTAL,
  });
}

export { setFIndProductData };
