import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData
} from '../../components/DataTableNew';

function Store() {
  return (
    <DataTable>
      <DataTableHead title="All Products" sub="Date 2021">
        <button
          className="btn btn-danger h-100 curve"
          style={{ fontSize: 15, fontWeight: 'bold', letterSpacing: 1 }}>
          ADD PRODUCT
        </button>
      </DataTableHead>

      <DataTableBody>
        <Table>
          <TableHead head="Barcode,Product Id,Product Name,Category,Subcategory,Warranty,Quantity,Buffer Stock,Vat,Cost Amount,Least Amount,Selling Price,Printed Price,Action" />

          <TableBody>
            <TableRaw>
              <TableData>P101</TableData>
              <TableData>1</TableData>
              <TableData>Transcend Strojet MH321 External Hard disk</TableData>
              <TableData>Hardisk</TableData>
              <TableData>External Hard disk</TableData>
              <TableData>03 Years</TableData>
              <TableData>35</TableData>
              <TableData>03</TableData>
              <TableData>0</TableData>
              <TableData>12500</TableData>
              <TableData>13000</TableData>
              <TableData>13500</TableData>
              <TableData>14500</TableData>
              <TableData>+</TableData>
            </TableRaw>
          </TableBody>
        </Table>
      </DataTableBody>

      <DataTableFooter>
        <span className="text-white">work</span>
      </DataTableFooter>
    </DataTable>
  );
}

export default Store;
