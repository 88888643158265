import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData,
} from "../../components/DataTableNew";
import PopUp from "../../components/PopUp";
import CreateProduct from "./CreateProduct";
import { useEffect, useState } from "react";
import api from "../../constant/api";
import axios from "axios";
import { useDispatch } from "react-redux";
import type from "../../constant/type";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
function Product() {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);

  useEffect(() => {
    fetchProduct(dispatch);
    fetchMainCategory(dispatch);
  }, []);

  return (
    <DataTable>
      <DataTableHead title="All Products" sub="Date 2021">
        <button
          className="btn btn-danger h-100 curve"
          style={{ fontSize: 15, fontWeight: "bold", letterSpacing: 1 }}
          onClick={(e) => {
            createProductPopup(dispatch);
          }}
        >
          CREATE PRODUCT
        </button>
      </DataTableHead>

      <DataTableBody>
        <Table>
          <TableHead head="Barcode,Product Id,Product Name,Category,Subcategory,Quantity,Buffer Stock,Vat,Discount,Cost Amount,Least Amount,Selling Price,Printed Price,Action" />

          <TableBody>
            {product.data.data.map((v, i) => {
              return (
                <TableRaw key={i}>
                  <TableData>ST{v.barcode}</TableData>
                  <TableData>{v.pid}</TableData>
                  <TableData>{v.name}</TableData>
                  <TableData>{v.category}</TableData>
                  <TableData>{v.sub_category}</TableData>

                  <TableData>{v.qty}</TableData>
                  <TableData>{v.buffer_stock}</TableData>
                  <TableData>{v.vat}</TableData>
                  <TableData>{v.discount}</TableData>
                  <TableData>{v.cost_amount}</TableData>
                  <TableData>{v.least_amount}</TableData>
                  <TableData>{v.sell_amount}</TableData>
                  <TableData>{v.printed_amount}</TableData>
                  <TableData>+</TableData>
                </TableRaw>
              );
            })}
          </TableBody>
        </Table>
      </DataTableBody>

      <DataTableFooter>
        <div className="d-flex justify-content-center align-items-center p-3">
          <div className="custom-pagination">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={product.data.current_page}
              itemsCountPerPage={product.data.per_page}
              totalItemsCount={product.data.total}
              onChange={(page) => {
                fetchProduct(dispatch, page);
              }}
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        </div>
      </DataTableFooter>

      <PopUp
        show={product.createProduct.popup}
        title="CREATE PRODUCT"
        close={(e) => {
          createProductPopup(dispatch);
        }}
      >
        <CreateProduct />
      </PopUp>
    </DataTable>
  );
}

//FETCH PRODUCT DATA AND STORE REDUX
function fetchProduct(dispatch, page = 1) {
  axios.get(api.getProduct + "?page=" + page).then((res) => {
    if (res.data.status) {
      dispatch({
        type: type.STORE_PRODUCT,
        payload: {
          data: res.data.data,
        },
      });
    } else {
      console.log("not fetch");
    }
  });
}

//FETCH MAIN CATEGORY AND STORE REDUX
export function fetchMainCategory(dispatch) {
  axios.get(api.getMainCategory).then((res) => {
    if (res.data.status) {
      dispatch({
        type: type.STORE_MAIN_CATEGORY,
        payload: {
          data: res.data.data,
        },
      });
    }
  });
}

//SHOW AND CLOSE POPUP
function createProductPopup(dispatch) {
  dispatch({
    type: type.SHOW_AND_CLOSE_POPUP,
  });
}

export default Product;
