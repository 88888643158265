import axios from "axios";
import api from "../constant/api";
import type from "../constant/type";

function readInvoice(dispatch, page = 1, param = "") {
  axios.get(api.getIvoice + "?page=" + page + "&" + param).then((res) => {
    const { status, data } = res.data;
    if (status) {
      dispatch({
        type: type.SAVE_INVOICE_DATA,
        payload: {
          data: data,
        },
      });
    }
  });
}

export { readInvoice };
