import React from 'react';

class Search extends React.Component {
    render() {
        return (
            <div className="search d-flex" style={{ height: this.props.height, width: this.props.width }}>
                <div className="input-search">
                    <input type="text" className="input-search-box form-control" placeholder="search" />
                </div>
                <div className="input-search-btn-wrapper">
                    <ion-icon name="search-outline"></ion-icon>
                </div>
            </div>
        );
    }
}

export default Search;
