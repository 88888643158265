import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
//import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import clone from '../redux/reducer/cloneObject';
import api from '../constant/api';
import { DateExtract } from '../logic/DateExtract';
import { invoiceData } from '../dummy/data';
import PopUp from '../components/PopUp';
import type from '../constant/type';
import paidImg from '../assets/img/paid.png';
import Number from '../logic/Number';
import Pagination from 'react-js-pagination';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableisLoad: true,
      showModel: false,
      invoiceDetails: {
        paid: '',
        invNo: ''
      },
      invoiceInfo: false,
      invoiceInfoItem: []
    };

    this.closePopup = this.closePopup.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.handleClickPrint = this.handleClickPrint.bind(this);
  }

  componentDidMount() {
    let newState = clone(this.state);
    const { dispatch } = this.props;
    const { getIvoice } = api;
    axios.get(getIvoice).then((res) => {
      const {
        status,
        data: { data }
      } = res.data;
      if (status) {
        console.log(data);
        dispatch({
          type: type.SAVE_INVOICE_DATA,
          payload: {
            data: data
          }
        });

        //hide load
        newState.tableisLoad = false;
        this.setState(newState);
      }
    });
  }

  showPopup(paid, invNo) {
    let newState = clone(this.state);
    let findData = false;
    const {
      dispatch,
      invoiceStore: { data }
    } = this.props;

    //FInd the invoice info details
    data.filter((v, i) => {
      if (v.invoice_no === invNo) {
        findData = data[i];
        newState.invoiceInfoItem = v.invoice_items;
      }
    });

    //store invoice info;
    newState.invoiceInfo = findData;

    newState.showModel = true;
    newState.invoiceDetails.invNo = invNo;
    newState.invoiceDetails.paid = paid;

    this.setState(newState);
  }
  closePopup() {
    let newState = clone(this.state);
    newState.showModel = false;
    this.setState(newState);
  }

  handleClickPaid(invNo) {
    let newState = clone(this.state);
    const {
      dispatch,
      invoiceStore: { data }
    } = this.props;
    console.log(data);
    axios.post(api.changePaymentStatus, { invoice_no: invNo }).then((res) => {
      if (res.data.status) {
        const chnageedStatus = res.data.paid;
        let findIndex = false;

        data.map((v, i) => {
          if (v.invoice_no == invNo) {
            findIndex = i;
            if (findIndex !== false) {
              dispatch({
                type: type.CHANGE_PAYMENT_STATUS,
                payload: {
                  index: findIndex,
                  paid: chnageedStatus
                }
              });
              console.log(findIndex);
            }
          }
        });
      }
    });
    console.log(invNo);
  }

  handleInvoiceDelete(invNo) {
    const {
      dispatch,
      invoiceStore: { data }
    } = this.props;

    axios.post(api.deleteInvoice, { invoice_no: invNo }).then((res) => {
      console.log(res.data);
      if (res.data.status) {
        const deletedId = invNo;
        dispatch({
          type: type.DELETE_INVOICE,
          payload: {
            id: deletedId
          }
        });

        this.closePopup();
      }
    });
  }

  handleClickPrint(data) {
    console.log(data);
  }

  render() {
    const date = new DateExtract();
    const { tableisLoad, showModel, invoiceDetails, invoiceInfo, invoiceInfoItem } = this.state;
    const {
      invoiceStore: {
        data,
        invoiceInfo: { info, item }
      }
    } = this.props;

    return (
      <DataTableWrapper footer={<TableFooter />}>
        <TableHead
          head={['Date', 'Invoice No', 'Company', 'Net Amount', 'Payment', 'Created by', 'Action']}
        />

        <TableBody>
          {data.map((v, i) => {
            return (
              <TableRow
                key={i}
                render={() => {
                  return (
                    <>
                      <TableData isLoad={tableisLoad}>
                        {date.humanReadbleDate(v.invoice_date, 'd-M-y', '/')}
                      </TableData>
                      <TableData isLoad={tableisLoad}>{v.invoice_no}</TableData>
                      <TableData isLoad={tableisLoad}> {v.short_code}</TableData>
                      <TableData isLoad={tableisLoad}>
                        {Number.thousandSeprater(v.net_amount)}
                      </TableData>
                      <TableData isLoad={tableisLoad}>
                        {v.payment_status == '1' ? (
                          <span class="badge bg-success">Paid</span>
                        ) : (
                          <span class="badge bg-danger">Unpaid</span>
                        )}
                      </TableData>
                      <TableData isLoad={tableisLoad}>{v.author}</TableData>
                      <TableActionBtn
                        eve={this.showPopup}
                        paid={v.payment_status}
                        invNo={v.invoice_no}
                      />
                    </>
                  );
                }}
              />
            );
          })}
        </TableBody>

        <PopUp show={showModel} close={this.closePopup} title="Invoice details">
          <div className="invoice-info-wrapper">
            <div className="invoice-info-head">
              <div className="invoice-cutomer-info">
                <div className="invoice-cutomer-info-item">
                  {invoiceInfo ? invoiceInfo.customer_name : 'unknown'}
                </div>
                <div className="invoice-cutomer-info-item">
                  {invoiceInfo.customer_company ? invoiceInfo.customer_company : 'unknown'}
                </div>
                <div className="invoice-cutomer-info-item">
                  {invoiceInfo.customer_address ? invoiceInfo.customer_address : 'unknown'}
                </div>
              </div>
              <div className="invoice-info-date-id">
                <div className="invoice-info-date-id-item">
                  {invoiceInfo.invoice_date
                    ? date.humanReadbleDate(invoiceInfo.invoice_date, 'd-M-y', '/')
                    : '00/00/0000'}
                </div>
                <div className="invoice-info-date-id-item">
                  {invoiceInfo.invoice_no ? '#' + invoiceInfo.invoice_no : '#0000'}
                </div>
              </div>
            </div>
            <div className="invoice-info-body">
              <DataTableWrapper>
                <TableHead head={['Item', 'Qty', 'Rate', 'Amount']} />
                <TableBody>
                  {invoiceInfoItem.map((v) => {
                    return (
                      <TableRow
                        render={() => {
                          return (
                            <>
                              <TableData isLoad={false}>{v.item_name}</TableData>
                              <TableData isLoad={false}>{v.qty}</TableData>
                              <TableData isLoad={false}>
                                {Number.thousandSeprater(v.price)}
                              </TableData>
                              <TableData isLoad={false}>
                                {Number.thousandSeprater(v.gross_amount)}
                              </TableData>
                            </>
                          );
                        }}
                      />
                    );
                  })}
                </TableBody>
              </DataTableWrapper>

              <div className="w-100 d-flex justify-content-end mt-2">
                <div className="d-flex flex-column">
                  <div className="invoice-item-amount">
                    <span>Sub total</span>
                    <span>
                      {invoiceInfo.net_amount
                        ? Number.thousandSeprater(invoiceInfo.net_amount)
                        : '00.00'}
                    </span>
                  </div>
                  <div className="invoice-item-amount">
                    <span>Adjustment</span>
                    <span>{invoiceInfo.discount ? invoiceInfo.discount : '00.00'}</span>
                  </div>
                  <div className="invoice-item-amount">
                    <span>Total</span>
                    <span>
                      {invoiceInfo.net_amount
                        ? Number.thousandSeprater(invoiceInfo.net_amount)
                        : '00.00'}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex mt-2">
                <div className="invoice-auther-info">
                  <span>Created by :</span>
                  <span>{invoiceInfo.author ? invoiceInfo.author : 'Unknown'}</span>
                </div>
              </div>
            </div>

            <div className="invoice-info-footer">
              <div className="w-75 d-flex">
                {invoiceInfo.payment_status === '0' ? (
                  <button
                    type="button"
                    className="btn btn-success m-1"
                    onClick={() => {
                      this.handleClickPaid(invoiceInfo.invoice_no);
                    }}>
                    Paid
                  </button>
                ) : (
                  <img src={paidImg} alt="paid" className="paid-image" />
                )}
                <PrintButton data={this.state.invoiceInfo} />
              </div>
              <div className="w-25 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger m-1"
                  onClick={() => {
                    this.handleInvoiceDelete(invoiceInfo.invoice_no);
                  }}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </PopUp>
      </DataTableWrapper>
    );
  }
}

class DataTableWrapper extends React.Component {
  render() {
    const { footer } = this.props;
    return (
      <div className="p-2 data-table-wrapper">
        <table className="table data-table">{this.props.children}</table>
        {footer}
      </div>
    );
  }
}

class TableHead extends React.Component {
  render() {
    const { head } = this.props;
    return (
      <thead className="data-table-head">
        <tr>
          {head.map((v) => {
            return (
              <th>
                <div>{v}</div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

class TableBody extends React.Component {
  render() {
    return <tbody className="data-table-body">{this.props.children}</tbody>;
  }
}
class TableRow extends React.Component {
  render() {
    const { render } = this.props;
    return <tr>{render()}</tr>;
  }
}

class TableData extends React.Component {
  render() {
    const { isLoad } = this.props;
    return (
      <td className="text-capitalize">
        <div>
          <span className={'w-100 text-center ' + (isLoad ? 'placeholder-glow' : '')}>
            <span className={'w-100 ' + (isLoad ? 'placeholder' : '')}>{this.props.children}</span>
          </span>
        </div>
      </td>
    );
  }
}

class TableFooter extends React.Component {
  render() {
    return (
      <div className="table-footer">
        <div className="adjust-raw-data">
          <span>Rows per page:</span>
        </div>
      </div>
    );
  }
}

class TableActionBtn extends React.Component {
  render() {
    return (
      <td className="text-capitalize">
        <div
          className="table-action-btn"
          onClick={(e) => {
            this.props.eve(this.props.paid, this.props.invNo);
          }}>
          <div className="icon">
            <ion-icon name="ellipsis-vertical"></ion-icon>
          </div>
        </div>
      </td>
    );
  }
}

function PrintButton(props) {
  function handleClick() {
    const {
      invoice_no,
      invoice_date,
      invoice_items,
      customer_address,
      customer_company,
      customer_name
    } = props.data;
    //customize array;
    let item = [];
    invoice_items.map((v, i) => {
      item.push({
        key: i + 1,
        data: {
          name: v.item_name,
          qty: v.qty,
          unit: v.price
        }
      });
    });

    let payload = {
      lastInvoiceNo: invoice_no,
      invoiceDate: invoice_date,
      item: item,
      to: {
        address: customer_address,
        company: customer_company,
        name: customer_name
      }
    };
  }

  return (
    <button
      type="button"
      className="btn btn-secondary m-1"
      onClick={(e) => {
        handleClick();
      }}>
      Print
    </button>
  );
}

const mapStateToProps = (state) => {
  return {
    invoiceStore: state.invoiceStore
  };
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch: dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
