import type from "../../../../constant/type";

function onChangeFinalAmount(e, dispatch) {
  let name = e.target.dataset.name;
  let currentValue = e.target.value ? e.target.value : "";

  let validationState = true;
  let floatRegex = /^[+-]?(?:\d*\.)?\d+$/;

  switch (name) {
    case "vat":
      if (currentValue) {
        if (floatRegex.test(currentValue)) {
          validationState = true;
          currentValue = parseFloat(currentValue);
        } else {
          validationState = false;
        }
      } else {
        currentValue = 0;
      }
      break;
    case "disPercentage":
      if (currentValue) {
        if (floatRegex.test(currentValue)) {
          validationState = true;
          currentValue = parseFloat(currentValue);
        } else {
          validationState = false;
        }
      } else {
        currentValue = 0;
      }
      break;
    case "discount":
      if (currentValue) {
        if (floatRegex.test(currentValue)) {
          validationState = true;
          currentValue = parseFloat(currentValue);
        } else {
          validationState = false;
        }
      } else {
        currentValue = 0;
      }
      break;

    default:
      break;
  }

  if (validationState) {
    dispatch({
      type: type.CHANAGE_FINAL_AMOUNT_VALUE,
      payload: {
        data: {
          name: name,
          value: currentValue,
        },
      },
    });
  } else {
    console.log("validation error.");
  }
}

export { onChangeFinalAmount };
