import type from "../../constant/type";
import clone from "./cloneObject";

//get data from local storage
let isOk = sessionStorage.getItem("auth") ? true : false;
let token = isOk ? sessionStorage.getItem("token") : false;
let store = {
  auth: {
    token: token,
    isOk: isOk,
    name: "unknown",
    role: "admin",
  },
};

const auth = (state = store, action) => {
  const { LOG_IN, LOG_OUT } = type;
  let newObj = clone(state);
  switch (action.type) {
    case LOG_IN:
      const { isOk, token } = action.payload;

      return Object.assign({}, state, {
        auth: {
          ...state.auth,
          token: token,
          isOk: isOk,
        },
      });

    case LOG_OUT:
      sessionStorage.clear();
      return Object.assign({}, state, {
        auth: {
          ...state.auth,
          token: false,
          isOk: false,
        },
      });

    default:
      return state;
  }
};

export { auth };
