function CalculateInvoiceTotal(item) {
  let tot = 0;
  Object.keys(item).map((v, i) => {
    if (item[v].lineTotal !== "" || item[v].lineTotal > 0) {
      tot += item[v].lineTotal;
    }
  });

  return tot;
}

export { CalculateInvoiceTotal };
