import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import api from "../../constant/api";
import {
  DataTable,
  DataTableHead,
  DataTableBody,
} from "../../components/DataTableNew";
import Divider from "../../components/Divider";
import AlertBox from "../../components/AlertBox";
import { fetchMainCategory } from "../product/Product";
const Category = () => {
  const defaultState = {
    category: {
      mainName: "",
      subName: "",
      data: [],
      main_id: "",
      alertMsg: {
        main: {
          status: "0",
          show: false,
          msg: "",
        },
        sub: {
          status: "0",
          show: false,
          msg: "",
        },
      },
    },
  };
  const [state, setState] = useState(defaultState);
  const dispatch = useDispatch();
  useEffect((e) => {
    fetchMainCategory(dispatch);
  }, []);
  const category = useSelector((state) => state.category);
  return (
    <div>
      <DataTable>
        <DataTableHead title="Category" />

        <DataTableBody>
          <AlertBox
            msg={state.category.alertMsg.main.msg}
            status={state.category.alertMsg.main.status}
            show={state.category.alertMsg.main.show}
            eve={(e) => {
              setState(
                Object.assign({}, state, {
                  category: {
                    ...state.category,
                    alertMsg: {
                      ...state.category.alertMsg,
                      main: {
                        status: "0",
                        show: false,
                        msg: "",
                      },
                    },
                  },
                })
              );
            }}
          />

          <div
            className="w-100 h-100 d-flex flex-column"
            style={{ minHeight: 285 }}
          >
            <div className="w-100 d-flex flex-column">
              <div class="card bg-transparent">
                <div
                  class="card-header  text-white"
                  style={{ fontWeight: "bold", letterSpacing: 1 }}
                >
                  CREATE CATEGORY
                </div>
                <div class="card-body">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-transparent text-white border-0"
                      style={{ width: "20%" }}
                    >
                      Category Name
                    </span>
                    <input
                      type="text"
                      className="form-control bg-dark border-0 text-white"
                      placeholder="Enter category name."
                      value={state.category.mainName}
                      onChange={(e) => {
                        setState(
                          Object.assign({}, state, {
                            category: {
                              ...state.category,
                              mainName: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <button
                      class="btn btn-danger"
                      type="button"
                      style={{ fontWeight: "bold", letterSpacing: 1 }}
                      onClick={(e) => {
                        createMainCategory(state, setState, dispatch);
                      }}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </div>

              <Divider />

              <div class="card bg-transparent">
                <AlertBox
                  msg={state.category.alertMsg.sub.msg}
                  status={state.category.alertMsg.sub.status}
                  show={state.category.alertMsg.sub.show}
                  eve={(e) => {
                    setState(
                      Object.assign({}, state, {
                        category: {
                          ...state.category,
                          alertMsg: {
                            ...state.category.alertMsg,
                            sub: {
                              status: "0",
                              show: false,
                              msg: "",
                            },
                          },
                        },
                      })
                    );
                  }}
                />
                <div
                  class="card-header  text-white"
                  style={{ fontWeight: "bold", letterSpacing: 1 }}
                >
                  CREATE SUB CATEGORY
                </div>
                <div class="card-body">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-transparent text-white border-0"
                      style={{ width: "20%" }}
                    >
                      Main Category
                    </span>
                    <select
                      class="form-select bg-dark text-white border-0"
                      data-name="brand_id"
                      value={state.category.main_id}
                      onChange={(e) => {
                        setState(
                          Object.assign({}, state, {
                            category: {
                              ...state.category,
                              main_id: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option value="">--- Select One ---</option>
                      {category.main.map((v, i) => {
                        return <option value={v.cid}>{v.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-transparent text-white border-0"
                      style={{ width: "20%" }}
                    >
                      Sub Category Name
                    </span>
                    <input
                      type="text"
                      className="form-control bg-dark border-0 text-white"
                      placeholder="Enter subcategory name."
                      value={state.category.subName}
                      onChange={(e) => {
                        setState(
                          Object.assign({}, state, {
                            category: {
                              ...state.category,
                              subName: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                  </div>

                  <div className="w-100 d-flex justify-content-end">
                    <button
                      class="btn btn-danger"
                      type="button"
                      style={{ fontWeight: "bold", letterSpacing: 1 }}
                      onClick={(e) => {
                        createSubCategory(state, setState, dispatch);
                      }}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DataTableBody>
      </DataTable>
    </div>
  );
};

function createMainCategory(state, setState, dispatch) {
  if (state.category.mainName !== "") {
    axios
      .post(api.createCategory, { name: state.category.mainName })
      .then((res) => {
        if (res.data.status) {
          setState(
            Object.assign({}, state, {
              category: {
                ...state.category,
                mainName: "",
                alertMsg: {
                  ...state.category.alertMsg,
                  main: {
                    status: "1",
                    show: true,
                    msg: "successfully main category created",
                  },
                },
              },
            })
          );
          fetchMainCategory(dispatch);
        }
      });
  }
}

function createSubCategory(state, setState, dispatch) {
  if (state.category.subName !== "" && state.category.main_id !== "") {
    axios
      .post(api.createSubCategory, {
        name: state.category.subName,
        cid: state.category.main_id,
      })
      .then((res) => {
        if (res.data.status) {
          setState(
            Object.assign({}, state, {
              category: {
                ...state.category,
                subName: "",
                main_id: "",
                alertMsg: {
                  ...state.category.alertMsg,
                  sub: {
                    status: "1",
                    show: true,
                    msg: "successfully Subcategory created",
                  },
                },
              },
            })
          );
        }
      });
  }
}
export default Category;
