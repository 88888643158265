import axios from "axios";
import api from "../../../../constant/api";
import type from "../../../../constant/type";

function fetchCustomerByShortCode(e, dispatch) {
  let currentValue = e.target.value ? e.target.value : "";

  if (currentValue) {
    axios.get(api.findCustomer + "?short=" + currentValue).then((res) => {
      if (res.data.status) {
        if (res.data.data.length > 0) {
          dispatch({
            type: type.STORE_FIND_CUSTOMER,
            payload: {
              data: res.data.data,
              show: true,
            },
          });
        } else {
          dispatch({
            type: type.STORE_FIND_CUSTOMER,
            payload: {
              data: [],
              show: false,
            },
          });
        }
      }
    });
  } else {
    console.log("no value");
    dispatch({
      type: type.STORE_FIND_CUSTOMER,
      payload: {
        show: false,
      },
    });
  }
}

export { fetchCustomerByShortCode };
