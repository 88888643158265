import axios from "axios";
import api from "../../../../constant/api";
import type from "../../../../constant/type";

function fetchProductWhenType(data, dispatch, key) {
  axios.get(api.findProduct + "?name=" + data).then((res) => {
    if (res.data.status) {
      if (res.data.data.length > 0) {
        dispatch({
          type: type.STORE_FIND_PRODUCT,
          payload: {
            data: res.data.data,
          },
        });
        dispatch({
          type: type.SHOW_SUGGEST,
          payload: {
            key: key,
            data: {
              suggest: true,
            },
          },
        });
      } else {
        dispatch({
          type: type.STORE_FIND_PRODUCT,
          payload: {
            data: [],
          },
        });
        dispatch({
          type: type.SHOW_SUGGEST,
          payload: {
            key: key,
            data: {
              suggest: false,
            },
          },
        });
      }
    }
  });
}

export { fetchProductWhenType };
