import type from "../../../constant/type";
const store = {
  main: [],
  sub: [],
};

const category = (state = store, action) => {
  const { STORE_MAIN_CATEGORY, STORE_SUB_CATEGORY } = type;

  switch (action.type) {
    case STORE_MAIN_CATEGORY:
      return Object.assign({}, state, {
        main: action.payload.data,
      });
    case STORE_SUB_CATEGORY:
      return Object.assign({}, state, {
        sub: action.payload.data,
      });
    default:
      return state;
  }
};

export { category };
