import type from '../../../../constant/type';

const onChangeInvoiceDate = (e, dispatch) => {
   dispatch({
      type: type.STORE_INVOICE_DATA,
      payload: {
         key: 'invoiceDate',
         data: {
            value: e.target.value,
         },
      },
   });
};

export { onChangeInvoiceDate };
