import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import axios from "axios";
import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData,
} from "../../components/DataTableNew";

import api from "../../constant/api.js";
import type from "../../constant/type.js";
import { DateExtract } from "../../logic/DateExtract.js";
import CreateCustomer from "./CreateCustomer";
import PopUp from "../../components/PopUp";

function Customer() {
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchCustomer(dispatch, 1);
  }, []);
  console.log(customer.createCustomer.popup);
  return (
    <DataTable>
      <DataTableHead title="All Customers">
        <button
          className="btn btn-danger h-100 curve"
          style={{ fontSize: 15, fontWeight: "bold", letterSpacing: 1 }}
          onClick={(e) => {
            createUserPopup(dispatch);
          }}
        >
          CREATE CUSTOMER
        </button>
      </DataTableHead>

      <DataTableBody>
        <Table>
          <TableHead head="ID,Short Code,Name,Position,Company,Address,City,Contact1,Contact2,Email,Attn1 Name,Attn1 Contact,Attn1 Email,Attn2 Name,Attn2 Contact,Attn2 Email,Action" />

          <TableBody>
            {customer.data.data.map((v, i) => {
              return (
                <TableRaw>
                  <TableData>{v.company_customers_id}</TableData>
                  <TableData>{v.short_code}</TableData>
                  <TableData>{v.customer_name}</TableData>
                  <TableData>{v.position}</TableData>
                  <TableData>{v.company}</TableData>
                  <TableData>{v.address}</TableData>
                  <TableData>{v.city}</TableData>
                  <TableData>{v.contact1}</TableData>
                  <TableData>{v.contact2}</TableData>
                  <TableData>{v.email}</TableData>
                  <TableData>{v.attn1_name}</TableData>
                  <TableData>{v.attn1_email}</TableData>
                  <TableData>{v.attn1_contact}</TableData>
                  <TableData>{v.attn2_name}</TableData>
                  <TableData>{v.attn2_email}</TableData>
                  <TableData>{v.attn2_contact}</TableData>
                  <TableData>+</TableData>
                </TableRaw>
              );
            })}
          </TableBody>
        </Table>
      </DataTableBody>

      <DataTableFooter>
        <div className="d-flex justify-content-center align-items-center p-3">
          <div className="custom-pagination">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={customer.data.current_page}
              itemsCountPerPage={customer.data.per_page}
              totalItemsCount={customer.data.total}
              onChange={(page) => {
                fetchCustomer(dispatch, page);
              }}
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        </div>
      </DataTableFooter>

      <PopUp
        show={customer.createCustomer.popup}
        title="CREATE CUSTOMER"
        close={(e) => {
          createUserPopup(dispatch);
        }}
      >
        <CreateCustomer />
      </PopUp>
    </DataTable>
  );
}

//SHOW AND CLOSE POPUP
export function createUserPopup(dispatch) {
  dispatch({
    type: type.SHOW_AND_CLOSE_CUSTOMER_POPUP,
  });
}

export function fetchCustomer(dispatch, page = 1) {
  axios.get(api.getCustomer + "?page=" + page).then((res) => {
    console.log(res.data.data);
    if (res.data.status) {
      dispatch({
        type: type.STORE_CUSTOMER,
        payload: {
          data: res.data.data,
        },
      });
    } else {
      console.log("not fetch");
    }
  });
}

export default Customer;
