import React from 'react';
import Search from './Search';
import { Link } from 'react-router-dom';
class TopBar extends React.Component {
  render() {
    return (
      <div className="top-bar">
        <div className="w-75 d-flex">
          <div className="w-25">
            <Link to="/invoice/create">
              <CircleIcon
                width="auto"
                txt="Create Invoice"
                height={40}
                ico="reader"
                cls="rounded-pill bg-danger"
              />
            </Link>
          </div>
          <div className="w-75">
            <Search width="100%" height={40} />
          </div>
        </div>

        <div className="w-25 d-flex justify-content-end">
          <CircleIcon width={40} height={40} ico="sunny-outline" />
          <CircleIcon width={40} height={40} ico="notifications-outline" isBadge={true} />
          <CircleIcon
            width={40}
            height={40}
            ico="chatbubble-ellipses-outline"
            isBadge={true}
            badgeColor="green"
          />
          <CircleIcon width={40} height={40} ico="settings-outline" />
        </div>
      </div>
    );
  }
}

class CircleIcon extends React.Component {
  static defaultProps = {
    isBadge: false,
    badgeColor: '#0099ff',
    txt: false,
    cls: false
  };

  render() {
    const { badgeColor, isBadge, txt, cls } = this.props;
    return (
      <div
        className={'circle-icon ' + (cls ? cls : '')}
        style={{ height: this.props.height, width: this.props.width, color: '#fff' }}>
        <ion-icon name={this.props.ico}></ion-icon>
        {txt ? <span style={{ marginLeft: 5, letterSpacing: 1, fontWeight: 500 }}>{txt}</span> : ''}
        <div
          className={'icon-badge ' + (!isBadge ? 'd-none ' : '')}
          style={{ backgroundColor: badgeColor }}></div>
      </div>
    );
  }
}

export default TopBar;
