import type from "../../../constant/type";
const store = {
  data: {
    current_page: "",
    per_page: "",
    total: "",
    data: [],
  },
  createProduct: {
    popup: false,
    notify: {
      message: "",
      messageShow: false,
      code: "0",
    },
  },
};

const product = (state = store, action) => {
  const { STORE_PRODUCT, SHOW_AND_CLOSE_POPUP, PRODUCT_NOTIFY } = type;

  switch (action.type) {
    case STORE_PRODUCT:
      return Object.assign({}, state, {
        data: action.payload.data,
      });
    case SHOW_AND_CLOSE_POPUP:
      return Object.assign({}, state, {
        createProduct: {
          ...state.createProduct,
          popup: state.createProduct.popup === false ? true : false,
        },
      });
    case PRODUCT_NOTIFY:
      return Object.assign({}, state, {
        createProduct: {
          ...state.createProduct,
          notify: action.payload.data,
        },
      });

    default:
      return state;
  }
};

export { product };
