import type from "../../../constant/type";
import {
  storeTo,
  storeItem,
  storePayment,
  storeAuther,
  storeDate,
  changeSaveStatus,
  notify,
  resetInvoice,
  storeNewInvoiceNo,
  ChangeFinalAmountValue,
  storeSalesRef,
  storeRemark,
} from "./logic/createInvoice";
import { CalculateLineTotal } from "../../../logic/CalculateLineTotal";
import { CalculateInvoiceTotal } from "../../../logic/CalculateInvoiceTotal";
import { CalculateNetAmount } from "../../../logic/CalculateNetAmount";
let store = {
  createInvoice: {
    to: {
      name: "",
      company: "",
      address: "",
      short: "",
      position: "",
      contact: "",
    },
    auther: sessionStorage.getItem("name"),
    salesRef: "",
    remark: "",
    invoiceDate: new Date().toISOString().substring(0, 10),
    inoiceNo: "",
    item: [],
    payment: {
      method: "credit",
      paid: "0",
    },
    newInvoiceNo: 0,
  },
  invoiceFeild: {
    key1: {
      name: "",
      qty: "",
      unit: "",
      discount: "",
      lineTotal: "",
      suggest: false,
    },
    key2: {
      name: "",
      qty: "",
      unit: "",
      discount: "",
      lineTotal: "",
      suggest: false,
    },
    key3: {
      name: "",
      qty: "",
      unit: "",
      discount: "",
      lineTotal: "",
      suggest: false,
    },
    key4: {
      name: "",
      qty: "",
      unit: "",
      discount: "",
      lineTotal: "",
      suggest: false,
    },
    key5: {
      name: "",
      qty: "",
      unit: "",
      discount: "",
      lineTotal: "",
      suggest: false,
    },
  },
  isSave: false,
  notify: {
    code: "1",
    message: "",
    save: false,
    show: false,
    new: false,
  },
  invoiceData: {
    data: [],
  },

  findedCustomer: {
    data: [],
    show: false,
  },
  findedProduct: {
    data: [],
  },
  finalTotal: {
    total: 0,
    net: 0,
    vat: 0,
    discount: 0,
    discountType: "%",
    disPercentage: 0,
  },
};

const invoice = (state = store, action) => {
  const {
    STORE_INVOICE_DATA,
    RESET_INVOICE_DATA,
    SAVE_INVOICE_DATA,
    ADD_NEW_INVOICE_ITEM,
    REMOVE_INVOICE_ITEM,
    STORE_FIND_CUSTOMER,
    SET_DEFAULT_INVOICE_CUSTOMER,
    STORE_FIND_PRODUCT,
    SET_DEFAULT_INVOICE_PRODUCT,
    SHOW_SUGGEST,
    CALCULATE_INVOICE_TOTAL,
    CHANAGE_FINAL_AMOUNT_VALUE,
    CHANGE_PAYMENT_STATUS,
    DELETE_INVOICE,
  } = type;

  switch (action.type) {
    case STORE_INVOICE_DATA:
      switch (action.payload.key) {
        case "to":
          return storeTo(state, action.payload);
        case "item":
          return storeItem(state, action.payload);
        case "payment":
          return storePayment(state, action.payload);
        case "auther":
          return storeAuther(state, action.payload);
        case "invoiceDate":
          return storeDate(state, action.payload);
        case "isSave":
          return changeSaveStatus(state, action.payload);
        case "notify":
          return notify(state, action.payload);
        case "newInvoiceNo":
          return storeNewInvoiceNo(state, action.payload);
        case "salesRef":
          return storeSalesRef(state, action.payload);
        case "remark":
          return storeRemark(state, action.payload);
        default:
          break;
      }

    default:
      break;
  }

  switch (action.type) {
    case RESET_INVOICE_DATA:
      return resetInvoice(state);
    case SAVE_INVOICE_DATA:
      return Object.assign({}, state, {
        invoiceData: action.payload.data,
      });
    case ADD_NEW_INVOICE_ITEM:
      const currentKeyCount = Object.keys(state.invoiceFeild).length;
      return Object.assign({}, state, {
        invoiceFeild: {
          ...state.invoiceFeild,
          [`key${currentKeyCount + 1}`]: {
            name: "",
            qty: "",
            unit: "",
            discount: "",
            lineTotal: "",
            suggest: false,
          },
        },
      });
    case REMOVE_INVOICE_ITEM:
      const removeKey = action.payload.key;
      let finalFeild = state.invoiceFeild;

      if (state.invoiceFeild[removeKey] !== undefined) {
        delete finalFeild[removeKey];
      }

      return Object.assign({}, state, {
        invoiceFeild: finalFeild,
      });

    case STORE_FIND_CUSTOMER:
      let show = state.findedCustomer.show;
      let data = state.findedCustomer.data;

      if (action.payload.show !== undefined) {
        show = action.payload.show;
      }

      if (action.payload.data !== undefined) {
        data = action.payload.data;
      }

      return Object.assign({}, state, {
        findedCustomer: {
          ...state.findedCustomer,
          data: data,
          show: show,
        },
      });

    case SET_DEFAULT_INVOICE_CUSTOMER:
      return Object.assign({}, state, {
        createInvoice: {
          ...state.createInvoice,
          to: action.payload.data,
        },
      });
    case STORE_FIND_PRODUCT:
      return Object.assign({}, state, {
        findedProduct: {
          ...state.findedProduct,
          data: action.payload.data,
        },
      });
    case SET_DEFAULT_INVOICE_PRODUCT:
      const defaultProductData = action.payload.data;
      let q = state.invoiceFeild[action.payload.key].qty;
      return Object.assign({}, state, {
        invoiceFeild: {
          ...state.invoiceFeild,
          [action.payload.key]: {
            ...state.invoiceFeild[action.payload.key],
            name: defaultProductData.name,
            discount: defaultProductData.discount,
            unit: defaultProductData.unit,
            qty: 1,
            lineTotal: CalculateLineTotal(
              q === "" || 0 ? 1 : q,
              defaultProductData.unit,
              defaultProductData.discount
            ),
          },
        },
      });

    case SHOW_SUGGEST:
      let currentProductData = state.invoiceFeild;
      let allProductRowKey = Object.keys(currentProductData);
      let showKey = action.payload.key;
      let changedStatus = action.payload.data.suggest;

      allProductRowKey.map((v, i) => {
        if (v === showKey) {
          currentProductData[v].suggest = changedStatus;
        } else {
          currentProductData[v].suggest = false;
        }
      });

      return Object.assign({}, state, {
        invoiceFeild: currentProductData,
      });
    case CALCULATE_INVOICE_TOTAL:
      let FinalTot = CalculateInvoiceTotal(state.invoiceFeild);
      return Object.assign({}, state, {
        finalTotal: {
          ...state.finalTotal,
          total: FinalTot,
          net: CalculateNetAmount(
            FinalTot,
            state.finalTotal.vat,
            state.finalTotal.discount
          ),
        },
      });
    case CHANAGE_FINAL_AMOUNT_VALUE:
      return ChangeFinalAmountValue(state, action.payload);
    case CHANGE_PAYMENT_STATUS:
      let prewData = state.invoiceData.data;
      prewData[action.payload.index].payment_status = action.payload.paid;

      return Object.assign({}, state, {
        invoiceData: {
          ...state.invoiceData,
          data: prewData,
        },
      });

    case DELETE_INVOICE:
      let prewDelData = state.invoiceData.data;
      let newStoreData = prewDelData.filter(
        (item) => item.invoice_no !== action.payload.invoice_no
      );

      console.log(newStoreData);
      return Object.assign({}, state, {
        invoiceData: {
          ...state.invoiceData,
          data: newStoreData,
        },
      });

    default:
      break;
  }

  return state;
};

export { invoice };
