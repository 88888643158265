import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import type from "../constant/type";

class SideBar extends Component {
  render() {
    const { layout, dispatch } = this.props;
    console.log(layout);
    return (
      <div className="side-bar">
        <div className="profile">
          <div className="avatar">
            <img
              src="https://picsum.photos/seed/picsum/200/300"
              className="avatar-image"
              alt="Profile"
            />
          </div>
          <div className={"user-details "}>
            <span>Royaltech | Admin</span>
            <span className="user-status">online</span>
          </div>
        </div>

        <div className="side-nav">
          <NavItem text="home" ico="home-outline" to="/" />
          <NavItem text="Invoices" ico="document-text-outline" to="/invoice" />
          <NavItem text="Products" ico="server-outline" to="/product" />
          <NavItem text="Sales" ico="bar-chart-outline" />
          <NavItem text="Payments" ico="cash-outline" />
          <NavItem text="Customers" ico="people-outline" to="/customers" />
          <NavItem text="Category" ico="list-outline" to="/category" />

          <NavItem text="Goals" ico="golf-outline" />
          <NavItem text="Settings" ico="settings-outline" />
          <div
            className="bg-danger"
            onClick={(e) => {
              dispatch({
                type: type.LOG_OUT,
              });
            }}
          >
            <NavItem text="Logout" ico="log-out-outline" to="#" />
          </div>
        </div>
      </div>
    );
  }
}

class NavItem extends React.Component {
  static defaultProps = {
    to: "/404",
    text: "unknown",
    ico: "alert-circle-outline",
  };

  isActive({ isActive }) {
    console.log("work");
    return isActive ? "active-nav" : "";
  }

  render() {
    const { ico, text, to } = this.props;

    return (
      <NavLink className={"side-nav-item " + this.isActive} to={to}>
        <ion-icon name={ico}></ion-icon>
        <span>{text}</span>
      </NavLink>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch: dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
