import React from "react";

function AlertBox(props) {
  const { msg, status, show, eve } = props;
  return (
    <div
      className={`alert app-alert ${status === "1" ? "ok" : "error"}  ${
        !show ? "d-none" : ""
      }`}
    >
      <span>{msg}</span>
      <button
        className={` btn alert-close-btn ${
          status === "1" ? "btn-success" : "btn-danger"
        } `}
        onClick={eve}
      >
        <ion-icon name="close"></ion-icon>
      </button>
    </div>
  );
}

AlertBox.defaultProps = {
  close: (e) => {},
};

export default AlertBox;
