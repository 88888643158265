import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Card } from "react-bootstrap";
import type from "../constant/type";
import clone from "../redux/reducer/cloneObject";
import { DateExtract } from "../logic/DateExtract";
import saveInvoice from "../logic/SaveInvoice";
import { Result, ResultItem } from "../components/Result";
//import GetLastInvoiceNo from '../logic/GetLastInvoiceNo';

// IMPORT CALLBACK EVENTS
import { onKeyUpInvoiceTo } from "./invoice/create/event/onKeyUpInvoiceTo";
import { onChnageInvoiceItem } from "./invoice/create/event/onChnageInvoiceItem";
import { onClickInvoicePayment } from "./invoice/create/event/onClickInvoicePayment";
import { onChnageInvoiceAuther } from "./invoice/create/event/onChnageInvoiceAuther";
import { onChangeInvoiceDate } from "./invoice/create/event/onChangeInvoiceDate";
import { onClickSaveInvoice } from "./invoice/create/event/onClickSaveInvoice";
import { getNewInvoiceNo } from "./invoice/create/event/getNewInvoiceNo";
import { fetchCustomerByShortCode } from "./invoice/create/event/fetchCustomerByShortCode";
import { setFIndCustomerData } from "./invoice/create/event/setFIndCustomerData";
import { setFIndProductData } from "./invoice/create/event/setFIndProductData";
import { onChangeFinalAmount } from "./invoice/create/event/onChangeFinalAmount";
import { onChangeSalesRef } from "./invoice/create/event/onChangeSalesRef";
import { onChangeRemark } from "./invoice/create/event/onChangeRemark";
// IMPORT COMPONENT
import Alert from "../components/Alert";

const currentDate = new Date();
const defaultDate = currentDate.toLocaleDateString();

class CreateInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceCreate: {
        to: {
          name: "",
          company: "",
          address: "",
          short: "",
        },
        auther: sessionStorage.getItem("name"),
        invoiceDate: defaultDate,
        inoiceNo: Math.floor(100000 + Math.random() * 900000),
        item: [],
        payment: {
          method: "credit",
          paid: 0,
        },
        lastInvoiceNo: 0,
      },
      isSave: false,
    };

    // BIND ALL REQIRED METHODS
    this.onKeyUpInvoiceAuther = this.onKeyUpInvoiceAuther.bind(this);
    this.onChangeInvoiceDate = this.onChangeInvoiceDate.bind(this);
    this.notifyWhenInvoiceSave = this.notifyWhenInvoiceSave.bind(this);
    this.savePaymentInfo = this.savePaymentInfo.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    getNewInvoiceNo(dispatch);
  }

  // PUSH THE LOCAL STATE INTO GLOBAL STATE (REDUX);
  componentDidUpdate() {
    this.props.dispatch({
      type: type.SAVE_INVOICE,
      payload: {
        data: this.state.invoiceCreate,
      },
    });
  }

  // STORE INVOICE AUTHER INFORMATION
  onKeyUpInvoiceAuther(e) {
    let newObj = clone(this.state);
    let currentValue = e.target.value ? e.target.value : "";
    newObj.invoiceCreate.auther = currentValue;
    this.setState(newObj);
  }

  // STORE INVOICE DATE
  onChangeInvoiceDate(e) {
    let newObj = clone(this.state);
    let currentValue = e.target.value;
    newObj.invoiceCreate.invoiceDate = currentValue;
    this.setState(newObj);
  }

  // STORE PAYMENT INFORMATION
  savePaymentInfo(e) {
    let newObj = clone(this.state);
    const { paid } = this.state.invoiceCreate.payment;
    const name = e.target.dataset.name;

    switch (name) {
      case "paid":
        if (paid === 0) {
          newObj.invoiceCreate.payment.paid = 1;
        } else if (paid === 1) {
          newObj.invoiceCreate.payment.paid = 0;
        }
        break;
      case "credit":
        newObj.invoiceCreate.payment.method = "credit";
        break;
      case "cash":
        newObj.invoiceCreate.payment.method = "cash";
        break;
      default:
        break;
    }
    this.setState(newObj);
    console.log(this.state.invoiceCreate);
  }

  //update the status when data save;
  notifyWhenInvoiceSave(status) {
    let newObj = clone(this.state);
    if (status) {
      newObj.isSave = false;
    } else {
      newObj.isSave = true;
    }
    this.setState(newObj);
  }

  render() {
    const { dispatch, invoice } = this.props;
    //call previous default value from redux
    let found = false;

    //destucture the object;
    const {
      invoiceCreate: { to, inoiceNo },
      isSave,
    } = this.state;

    return (
      <div className="w-100 h-100 p-2 create-invoice-page">
        <div className="add-bill-wrapper">
          <div className="bill-header">
            <span>Create your invoice</span>
            <Alert
              msg={invoice.notify.message}
              status={invoice.notify.code}
              show={invoice.notify.show}
              dispatch={dispatch}
            />
          </div>

          <div className="invoice-details-wrapper">
            <div className="left-details d-flex flex-row">
              <div className="bill-to w-50">
                <div className="bill-to-head">
                  <span className="invoice-to-message">invoice to</span>
                </div>

                <div className="mb-2 position-relative">
                  <input
                    type="text"
                    data-name="short"
                    value={invoice.createInvoice.to.short}
                    onChange={(e) => {
                      onKeyUpInvoiceTo(e, dispatch);
                      fetchCustomerByShortCode(e, dispatch);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        dispatch({
                          type: type.STORE_FIND_CUSTOMER,
                          payload: {
                            show: false,
                          },
                        });
                      }
                    }}
                    class="form-control w-75 rounded-0"
                    placeholder="Short code"
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: 40,
                      left: 0,
                      zIndex: 2021,
                    }}
                    className={`bg-dark w-75 d-flex  ${
                      invoice.findedCustomer.show === false ? "d-none" : ""
                    }`}
                  >
                    <Result
                      title="Finded Customers :-"
                      close={(e) => {
                        dispatch({
                          type: type.STORE_FIND_CUSTOMER,
                          payload: {
                            show: false,
                          },
                        });
                      }}
                      render={() => {
                        return invoice.findedCustomer.data.map((v, i) => {
                          return (
                            <ResultItem
                              text={v.short_code}
                              eve={(e) => {
                                setFIndCustomerData(e, dispatch, invoice);
                              }}
                              dataKey={i}
                              key={v.company_customers_id}
                            />
                          );
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="mb-2">
                  <input
                    type="text"
                    data-name="name"
                    value={invoice.createInvoice.to.name}
                    onChange={(e) => {
                      onKeyUpInvoiceTo(e, dispatch);
                    }}
                    class="form-control w-75 rounded-0"
                    placeholder="Name"
                  />
                </div>

                <div className="mb-2">
                  <input
                    type="text"
                    data-name="position"
                    class="form-control w-75 rounded-0"
                    placeholder="Position"
                    value={invoice.createInvoice.to.position}
                    onChange={(e) => {
                      onKeyUpInvoiceTo(e, dispatch);
                    }}
                  />
                </div>

                <div className="mb-2">
                  <input
                    type="text"
                    data-name="company"
                    value={invoice.createInvoice.to.company}
                    onChange={(e) => {
                      onKeyUpInvoiceTo(e, dispatch);
                    }}
                    class="form-control w-75 rounded-0"
                    placeholder="Company"
                  />
                </div>

                <div className="mb-2">
                  <input
                    type="text"
                    data-name="place"
                    value={invoice.createInvoice.to.address}
                    onChange={(e) => {
                      onKeyUpInvoiceTo(e, dispatch);
                    }}
                    class="form-control w-75 rounded-0"
                    placeholder="Address"
                  />
                </div>

                <div className="mb-2 position-relative">
                  <input
                    type="text"
                    data-name="contact"
                    class="form-control w-75 rounded-0"
                    placeholder="Contact Number"
                    value={invoice.createInvoice.to.contact}
                    onChange={(e) => {
                      onKeyUpInvoiceTo(e, dispatch);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="right-details">
              <div className="bill-info">
                <div className="bill-info-head">
                  <span className="invoice-to-message">bill info</span>
                </div>

                <div className="d-flex flex-column align-items-end mb-2">
                  <label
                    className="form-label text-white"
                    style={{ fontWeight: 500 }}
                  >
                    Invoice No
                  </label>
                  <span className="badge invoice-no-badge">
                    #{invoice.createInvoice.newInvoiceNo}
                  </span>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label text-white"
                    style={{ fontWeight: 500 }}
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    value={invoice.createInvoice.invoiceDate}
                    onChange={(e) => {
                      onChangeInvoiceDate(e, dispatch);
                    }}
                    className="form-control  rounded-0"
                    style={{ width: "50%" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="invoice-make-area-wrapper">
            <div className="invoice-make-head">
              <div className="invoice-make-head-item" style={{ width: "40%" }}>
                <span className="bold">Product</span>
              </div>

              <div className="invoice-make-head-item" style={{ width: "10%" }}>
                <span className="bold">Qty</span>
              </div>

              <div className="invoice-make-head-item" style={{ width: "15%" }}>
                <span className="bold">Unit Rate</span>
              </div>

              <div className="invoice-make-head-item" style={{ width: "15%" }}>
                <span className="bold">Discount</span>
              </div>

              <div className="invoice-make-head-item" style={{ width: "15%" }}>
                <span className="bold">Line Total</span>
              </div>

              <div className="invoice-make-head-item" style={{ width: "5%" }}>
                <span className="bold">R</span>
              </div>
            </div>

            {Object.keys(invoice.invoiceFeild).map((v, i) => {
              return (
                <InvoiceItem
                  key={i}
                  feild={v}
                  dispatch={dispatch}
                  data={invoice.invoiceFeild[v]}
                  eve={onChnageInvoiceItem}
                  inx={i}
                  findProduct={invoice.findedProduct.data}
                />
              );
            })}
          </div>

          <div className="w-100 d-flex m-2">
            <button
              className="btn btn-dark shadow p-0"
              style={{ width: 60 }}
              onClick={(e) => {
                dispatch({
                  type: type.ADD_NEW_INVOICE_ITEM,
                });
              }}
            >
              <span style={{ fontSize: 20 }}>
                <ion-icon name="add"></ion-icon>
              </span>
            </button>
          </div>

          {/* Remark & Final Calculation  */}
          <div className="row p-3">
            <div className="col-6 d-flex flex-column">
              <FinalCalculationItem>
                <div className="col-12 p-0 m-0">
                  <div class="mb-2">
                    <label className="form-label text-white">
                      <FinalCalculationTital text="remark" />
                    </label>
                    <textarea
                      className="form-control bg-dark text-white"
                      rows="2"
                      value={invoice.createInvoice.remark}
                      onChange={(e) => {
                        onChangeRemark(e, dispatch);
                      }}
                    ></textarea>
                  </div>
                </div>
              </FinalCalculationItem>
              <FinalCalculationItem>
                <div className="col-12 p-0 m-0">
                  <div class="mb-2">
                    <label className="form-label text-white">
                      <FinalCalculationTital text="payment method" />
                    </label>
                    <div className="d-flex">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginRight: 40 }}
                      >
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          data-name="cash"
                          defaultValue="cash"
                          checked={
                            invoice.createInvoice.payment.method === "cash"
                              ? true
                              : false
                          }
                          name="payment_method"
                          onClick={(e) => {
                            onClickInvoicePayment(e, dispatch);
                          }}
                          id="cash"
                        />
                        <label
                          for="cash"
                          className="text-white"
                          style={{ marginLeft: 10 }}
                        >
                          Cash
                        </label>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginRight: 40 }}
                      >
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          data-name="credit"
                          defaultValue="credit"
                          checked={
                            invoice.createInvoice.payment.method === "credit"
                              ? true
                              : false
                          }
                          name="payment_method"
                          onClick={(e) => {
                            onClickInvoicePayment(e, dispatch);
                          }}
                          id="credit"
                        />
                        <label
                          for="credit"
                          className="text-white"
                          style={{ marginLeft: 10 }}
                        >
                          Credit
                        </label>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          data-name="card"
                          defaultValue="card"
                          checked={
                            invoice.createInvoice.payment.method === "card"
                              ? true
                              : false
                          }
                          name="payment_method"
                          onClick={(e) => {
                            onClickInvoicePayment(e, dispatch);
                          }}
                          id="card"
                        />
                        <label
                          for="card"
                          className="text-white"
                          style={{ marginLeft: 10 }}
                        >
                          Card
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FinalCalculationItem>
            </div>
            <div className="col-6 d-flex flex-column">
              <FinalCalculationItem>
                <div className="col-4 p-0 m-0">
                  <FinalCalculationTital text="total" />
                </div>
                <div className="col-8 p-0 m-0">
                  <FinalCalculationTextBox
                    name="total"
                    value={invoice.finalTotal.total}
                    read={true}
                  />
                </div>
              </FinalCalculationItem>

              <FinalCalculationItem>
                <div className="col-4 p-0 m-0">
                  <FinalCalculationTital text="vat" />
                </div>
                <div className="col-8 p-0 m-0">
                  <FinalCalculationTextBox
                    name="vat"
                    read={false}
                    value={invoice.finalTotal.vat}
                    onChange={(e) => {
                      onChangeFinalAmount(e, dispatch);
                    }}
                  />
                </div>
              </FinalCalculationItem>

              <FinalCalculationItem>
                <div className="col-4 p-0 m-0">
                  <FinalCalculationTital text="Tot DISCOUNT" />
                </div>
                <div className="col-8 p-0 m-0 d-flex">
                  <div className="w-50 d-flex">
                    <FinalCalculationTextBox
                      name="disPercentage"
                      read={false}
                      value={invoice.finalTotal.disPercentage}
                      onChange={(e) => {
                        onChangeFinalAmount(e, dispatch);
                      }}
                    />
                    <div
                      className="d-flex align-items-center h-100 text-white bg-dark"
                      style={{ paddingLeft: 15, paddingRight: 15 }}
                    >
                      %
                    </div>
                  </div>
                  <div className="w-50">
                    <FinalCalculationTextBox
                      name="discount"
                      value={invoice.finalTotal.discount}
                      read={false}
                      onChange={(e) => {
                        onChangeFinalAmount(e, dispatch);
                      }}
                    />
                  </div>
                </div>
              </FinalCalculationItem>

              <FinalCalculationItem>
                <div className="col-4 p-0 m-0">
                  <FinalCalculationTital text="net amount" />
                </div>
                <div className="col-8 p-0 m-0">
                  <FinalCalculationTextBox
                    name="net"
                    value={invoice.finalTotal.net}
                    read={true}
                  />
                </div>
              </FinalCalculationItem>
            </div>
          </div>

          <Card className="auther-info border-0">
            <Card.Body className="d-flex p-3 mb-3 ">
              <Form.Group
                controlId="formBasicEmail"
                className="w-25"
                style={{ paddingRight: 10 }}
              >
                <Form.Label>Created by</Form.Label>
                <Form.Control
                  type="text"
                  data-name="auther"
                  value={invoice.createInvoice.auther}
                  onChange={(e) => {
                    onChnageInvoiceAuther(e, dispatch);
                  }}
                  className="w-100"
                />
              </Form.Group>
              <Form.Group
                controlId="formBasicEmail"
                className="w-25"
                style={{ paddingRight: 10 }}
              >
                <Form.Label>Sales Ref</Form.Label>
                <Form.Control
                  type="text"
                  data-name="salesRef"
                  value={invoice.createInvoice.salesRef}
                  className="w-100"
                  onChange={(e) => {
                    onChangeSalesRef(e, dispatch);
                  }}
                />
              </Form.Group>
            </Card.Body>
          </Card>

          <Card className="payment-info border-0">
            <Card.Body className="d-flex p-3 flex-column">
              <div className="d-flex mb-3 align-items-center">
                <span>Payment Status :</span>

                <label
                  for="paid"
                  className="text-white"
                  style={{ marginRight: 10 }}
                >
                  Paid :
                </label>
                <input
                  className="form-check-input mt-0"
                  type="radio"
                  data-name="paid"
                  defaultValue="1"
                  checked={
                    invoice.createInvoice.payment.paid === "1" ? true : false
                  }
                  name="payment_paid"
                  id="paid"
                  onClick={(e) => {
                    onClickInvoicePayment(e, dispatch);
                  }}
                  style={{ marginRight: 10 }}
                />

                <label
                  for="unpaid"
                  className="text-white"
                  style={{ marginRight: 10 }}
                >
                  Unpaid :
                </label>
                <input
                  className="form-check-input mt-0"
                  type="radio"
                  data-name="paid"
                  defaultValue="0"
                  checked={
                    invoice.createInvoice.payment.paid === "0" ? true : false
                  }
                  id="unpaid"
                  name="payment_paid"
                  onClick={(e) => {
                    onClickInvoicePayment(e, dispatch);
                  }}
                />
              </div>
            </Card.Body>
          </Card>

          <div className="d-flex justify-content-between">
            <button
              className="btn btn-success invoice-action-btn"
              onClick={(e) => {
                onClickSaveInvoice(e, dispatch, invoice);
              }}
              data-type="save"
            >
              <span
                data-type="save"
                className={invoice.notify.save ? "d-none" : ""}
              >
                Save
              </span>
              <span
                className={
                  "spinner-border spinner-border-sm " +
                  (invoice.notify.save ? "" : "d-none")
                }
              ></span>
            </button>

            <button
              className="btn btn-primary invoice-action-btn"
              onClick={(e) => {
                onClickSaveInvoice(e, dispatch, invoice);
              }}
              data-type="new"
            >
              <span
                data-type="new"
                className={invoice.notify.new ? "d-none" : ""}
              >
                New
              </span>
              <span
                className={
                  "spinner-border spinner-border-sm " +
                  (invoice.notify.new ? "" : "d-none")
                }
              ></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

/*-------------- INVOICE ITEM COMPONENT ----------------*/

class InvoiceItem extends React.Component {
  render() {
    const { dispatch, eve, feild, data, inx, findProduct } = this.props;
    return (
      <div className="invoice-item-input" key={feild}>
        <div className="invoice-item-input-box" style={{ width: "40%" }}>
          <Form.Group
            controlId="formBasicEmail"
            className="w-100 position-relative"
          >
            <Form.Control
              type="text"
              data-type="name"
              data-key={feild}
              value={data.name}
              onChange={(e) => {
                eve(e, dispatch);
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  dispatch({
                    type: type.SHOW_SUGGEST,
                    payload: {
                      key: feild,
                      data: {
                        suggest: false,
                      },
                    },
                  });
                }
              }}
            />

            <div
              className={`list-product-suggest ${
                data.suggest === false ? "d-none" : ""
              } `}
              style={{ zIndex: 100 + inx }}
            >
              <Result
                title="Finded Products :-"
                close={(e) => {
                  dispatch({
                    type: type.SHOW_SUGGEST,
                    payload: {
                      key: feild,
                      data: {
                        suggest: false,
                      },
                    },
                  });
                }}
                render={() => {
                  return findProduct.map((v, i) => {
                    return (
                      <ResultItem
                        text={v.name}
                        eve={(e) => {
                          console.log("onClick");
                          setFIndProductData(e, dispatch, findProduct, feild);
                        }}
                        dataKey={i}
                        key={i}
                      />
                    );
                  });
                }}
              />
            </div>
          </Form.Group>
        </div>

        <div className="invoice-item-input-box" style={{ width: "10%" }}>
          <Form.Group controlId="formBasicEmail" className="w-100">
            <Form.Control
              type="text"
              data-type="qty"
              data-key={feild}
              value={data.qty}
              onChange={(e) => {
                eve(e, dispatch);
              }}
            />
          </Form.Group>
        </div>

        <div className="invoice-item-input-box" style={{ width: "15%" }}>
          <Form.Group controlId="formBasicEmail" className="w-100">
            <Form.Control
              type="text"
              data-type="unit"
              data-key={feild}
              value={data.unit}
              onChange={(e) => {
                eve(e, dispatch);
              }}
            />
          </Form.Group>
        </div>

        <div className="invoice-item-input-box" style={{ width: "15%" }}>
          <Form.Group controlId="formBasicEmail" className="w-100">
            <Form.Control
              type="text"
              data-type="discount"
              data-key={feild}
              value={data.discount}
              onChange={(e) => {
                eve(e, dispatch);
              }}
            />
          </Form.Group>
        </div>

        <div className="invoice-item-input-box" style={{ width: "15%" }}>
          <Form.Group controlId="formBasicEmail" className="w-100">
            <Form.Control
              type="text"
              data-type="lineTotal"
              data-key={feild}
              value={data.lineTotal}
              readOnly={true}
            />
          </Form.Group>
        </div>
        <div className="invoice-item-input-box" style={{ width: "5%" }}>
          <button
            className="btn btn-dark shadow p-0"
            style={{ width: 60 }}
            onClick={(e) => {
              dispatch({
                type: type.REMOVE_INVOICE_ITEM,
                payload: {
                  key: feild,
                },
              });
              dispatch({
                type: type.CALCULATE_INVOICE_TOTAL,
              });
            }}
          >
            <span style={{ fontSize: 20 }}>
              <ion-icon name="close"></ion-icon>
            </span>
          </button>
        </div>
      </div>
    );
  }
}

//-------------------------- [ LOGICS ] --------------------------------\\

/* This event method will call when user key up this feilds ( Customer,Company,Address,Short_code ) */
function handleKeyUpUserDetail(e, dispatch, data) {
  let Datatype = e.target.dataset.name;
  let currentValue = e.target.value ? e.target.value : false;

  let name = false;
  let company = false;
  let place = false;
  let short = false;

  switch (Datatype) {
    case "name":
      name = currentValue;
      break;
    case "company":
      company = currentValue;
      break;
    case "place":
      place = currentValue;
      break;
    case "short":
      short = currentValue;
      break;

    default:
      break;
  }

  //store the cutomer data

  dispatch({
    type: type.STORE_CUSTOMER_DATA,
    payload: {
      name: name,
      company: company,
      place: place,
      short_code: short,
    },
  });

  console.log(data);
}

/* store the invoice date */
function onChangeDate(e, dispatch) {
  dispatch({
    type: type.STORE_INVOICE_DATE,
    payload: {
      date: e.target.value,
    },
  });
}

/* store the auther information ( who made the invoice ) */
function onKeyUpAuther(e, dispatch) {
  let currentValue = e.target.value ? e.target.value : false;

  dispatch({
    type: type.STORE_AUTHER_DATA,
    payload: {
      auther: currentValue,
    },
  });
}

/* store the invoice item details */
function handleKeyUpInvoiceItem(e, data, dispatch) {
  let Datatype = e.target.dataset.type;
  let key = e.target.dataset.key;
  let currentValue = e.target.value ? e.target.value : false;

  let name = false;
  let qty = false;
  let unit = false;
  let discount = false;
  let lineTotal = false;

  let dataMached = false;

  switch (Datatype) {
    case "name":
      name = currentValue;
      break;
    case "qty":
      qty = currentValue;
      break;
    case "unit":
      unit = currentValue;
      break;
    case "discount":
      discount = currentValue;
      break;
    case "lineTotal":
      lineTotal = currentValue;
      break;
    default:
      break;
  }
  data.map((v, i) => {
    if (v.key === key) {
      dispatch({
        type: type.STORE_INVOICE_ITEM,
        payload: {
          key: key,
          name: name ? name : v.data.name,
          unit: unit ? parseInt(unit) : parseInt(v.data.unit),
          qty: qty ? parseInt(qty) : parseInt(v.data.qty),
          discount: discount ? parseInt(discount) : parseInt(v.data.discount),
          lineTotal: lineTotal
            ? parseInt(lineTotal)
            : parseInt(v.data.lineTotal),
        },
        isNew: false,
        pos: i,
      });

      dataMached = true;
    }
  });

  if (!dataMached) {
    dispatch({
      type: type.STORE_INVOICE_ITEM,
      payload: {
        key: key,
        name: name,
        unit: unit,
        qty: qty,
        discount: discount,
        lineTotal: lineTotal,
      },
      isNew: true,
    });
  }
}

function FinalCalculationTital(props) {
  return (
    <div
      className="w-100 h-100 text-white text-uppercase d-flex align-items-center"
      style={{ fontWeight: "bold", letterSpacing: 2 }}
    >
      {props.text}
    </div>
  );
}

function FinalCalculationTextBox(props) {
  const { value, name, read, placeholder, onChange } = props;
  return (
    <input
      type="text"
      data-name={name}
      class="form-control bg-dark rounded-0 text-white w-100"
      value={value}
      readOnly={read}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
FinalCalculationTextBox.defaultProps = {
  onChange: () => {},
};

function FinalCalculationItem(props) {
  return <div className="row w-100 m-0 p-0 mb-2">{props.children}</div>;
}
const mapStateToProps = (state) => {
  return {
    invoice: state.invoice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
