import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import api from "../constant/api";
import clone from "../redux/reducer/cloneObject";

import type from "../constant/type";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        email: "",
        password: "",
        isLoad: false,
      },
    };
    this.onKeyUpLogin = this.onKeyUpLogin.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
  }

  onSubmitLogin(e) {
    const { login } = this.state;
    let newObj = clone(this.state);

    if (login.password && login.email) {
      const data = {
        email: login.email,
        password: login.password,
      };
      newObj.login.isLoad = true;
      this.setState(newObj);

      axios.post(api.authLogin, data).then((res) => {
        let status = res.data.status;
        const { dispatch } = this.props;
        if (status) {
          newObj.login.isLoad = false;
          sessionStorage.setItem("auth", true);
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("name", res.data.name);

          dispatch({
            type: type.LOG_IN,
            payload: {
              isOk: true,
              token: res.data.token,
            },
          });

          this.setState(newObj);

          window.location.assign("/");
        } else {
          sessionStorage.setItem("isAuth", false);
          sessionStorage.setItem("token", "");
          newObj.login.isLoad = false;
          this.setState(newObj);
        }
      });
    } else {
      newObj.login.isLoad = false;
      this.setState(newObj);
    }
  }

  onKeyUpLogin(e) {
    let val = e.target.value ? e.target.value : false;
    let type = e.target.dataset.name;
    let newObj = clone(this.state);

    switch (type) {
      case "email":
        newObj.login.email = val;
        break;
      case "password":
        newObj.login.password = val;
        break;
      default:
        break;
    }

    this.setState(newObj);
  }

  render() {
    const { login } = this.state;
    return (
      <div className="vh-100 w-100 d-flex justify-content-center align-items-center app-login">
        <div className="card w-25">
          <div className="card-header">Admin</div>
          <div className="card-body">
            <div className="mb-3">
              <label for="exampleFormControlInput1" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                data-name="email"
                defaultValue={login.email ? login.email : ""}
                onKeyUp={(e) => {
                  this.onKeyUpLogin(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label for="exampleFormControlInput1" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                data-name="password"
                defaultValue={login.password ? login.password : ""}
                onKeyUp={(e) => {
                  this.onKeyUpLogin(e);
                }}
              />
            </div>
          </div>

          <div class="card-footer text-muted d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary w-100"
              onClick={(e) => {
                this.onSubmitLogin(e);
              }}
            >
              <span className={login.isLoad ? "d-none" : "d-block"}>Login</span>
              <span
                className={
                  "spinner-border spinner-border-sm " +
                  (login.isLoad ? "" : "d-none")
                }
              ></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
