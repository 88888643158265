import type from '../../../../constant/type';

const onChnageInvoiceAuther = (e, dispatch) => {
  let value = e.target.value ? e.target.value : '';
  dispatch({
    type: type.STORE_INVOICE_DATA,
    payload: {
      key: 'auther',
      data: {
        value: value
      }
    }
  });
};

export { onChnageInvoiceAuther };
