import axios from "axios";
import api from "../constant/api";
import type from "../constant/type";

function readCustomerCode(dispatch) {
  axios.get(api.getInvoiceCustomerCode).then((res) => {
    const { status, data } = res.data;
    if (status) {
      dispatch({
        type: type.STORE_CUSTOMER_CODE,
        payload: {
          data: data,
        },
      });
    }
  });
}

export { readCustomerCode };
