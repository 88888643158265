import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import * as yup from "yup";

//components
import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData,
} from "../../components/DataTableNew.js";

import api from "../../constant/api.js";
import type from "../../constant/type.js";
import { DateExtract } from "../../logic/DateExtract.js";
import Number from "../../logic/Number.js";
import InvoiceInfo from "./InvoiceInfo";
import { Filter, FilterItem } from "../../components/Filter";
//Logic
import { ChangeState } from "../../logic/ChangeState";
import { readInvoice } from "../../logic/invoice";
import { readCustomerCode } from "../../logic/customer";
function Invoice() {
  const dispatch = useDispatch();
  useEffect(() => {
    readInvoice(dispatch);
    readCustomerCode(dispatch);
  }, []);

  const defaultState = {
    show: false,
    info: false,
    param: "",
  };
  const [state, setState] = useState(defaultState);
  const invoiceStore = useSelector((state) => state.invoice);
  const customer = useSelector((state) => state.customer);
  const date = new DateExtract();

  const formik = useFormik({
    initialValues: {
      invoice_no: "",
      payment_status: "",
      min: "",
      max: "",
      from: "",
      to: "",
      from_to_by: "",
      short_code: "",
    },
    validationSchema: yup.object({
      invoice_no: yup.number(),
      payment_status: yup.number(),
      min: yup.number(),
      max: yup.number(),
    }),
    onSubmit: (formData) => {
      let param = "";
      Object.keys(formData).map((key) => {
        if (formData[key] !== "") {
          param += key + "=" + formData[key] + "&";
        }
      });
      if (param !== "") {
        readInvoice(dispatch, invoiceStore.invoiceData.currentPage, param);
      }
      setState(
        ChangeState(state, {
          param: param,
        })
      );
    },
  });

  return (
    <>
      <DataTable>
        <DataTableHead title="All Invoices" sub="Date 2021" />

        <DataTableBody>
          <div className="w-100">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <Filter
                reset={(e) => {
                  formik.resetForm();
                  setState(
                    ChangeState(state, {
                      param: "",
                    })
                  );
                  readInvoice(dispatch, 1, "");
                }}
              >
                <FilterItem
                  type="text"
                  label="Invoice no"
                  name="invoice_no"
                  value={formik.values.invoice_no}
                  change={formik.handleChange}
                />

                <FilterItem
                  type="select"
                  label="Customer"
                  name="short_code"
                  change={formik.handleChange}
                  value={formik.values.short_code}
                  render={() => {
                    return customer.short_code.map((v, i) => {
                      return (
                        <option value={v.short_code} key={i}>
                          {v.short_code}
                        </option>
                      );
                    });
                  }}
                />

                <FilterItem
                  type="select"
                  label="Payment"
                  name="payment_status"
                  change={formik.handleChange}
                  value={formik.values.payment_status}
                  render={() => {
                    return (
                      <>
                        <option value="1">Paid</option>
                        <option value="0">Unpaid</option>
                      </>
                    );
                  }}
                />
                <FilterItem
                  type="select"
                  label="This"
                  name="from_to_by"
                  change={formik.handleChange}
                  value={formik.values.from_to_by}
                  render={() => {
                    return (
                      <>
                        <option value="day">Day</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </>
                    );
                  }}
                />
                <FilterItem
                  type="date"
                  label="From"
                  name="from"
                  value={formik.values.from}
                  change={formik.handleChange}
                />
                <FilterItem
                  type="date"
                  label="To"
                  name="to"
                  value={formik.values.to}
                  change={formik.handleChange}
                />
                <FilterItem
                  type="text"
                  label="Min"
                  name="min"
                  value={formik.values.min}
                  change={formik.handleChange}
                />
                <FilterItem
                  type="text"
                  label="Max"
                  name="max"
                  value={formik.values.max}
                  change={formik.handleChange}
                />
              </Filter>
            </form>
          </div>
          <Table>
            <TableHead head="Date,Invoice No,Company,Net Amount,Payment,Created by,Action" />

            <TableBody>
              {invoiceStore.invoiceData.data.map((v, i) => {
                return (
                  <TableRaw key={i}>
                    <TableData>
                      {date.humanReadbleDate(v.invoice_date, "d-M-y", "/")}
                    </TableData>
                    <TableData>{v.invoice_no}</TableData>
                    <TableData>{v.short_code}</TableData>

                    <TableData>
                      {Number.thousandSeprater(v.net_amount)}
                    </TableData>
                    <TableData>
                      {v.payment_status == "1" ? (
                        <span class="badge bg-success">Paid</span>
                      ) : (
                        <span class="badge bg-danger">Unpaid</span>
                      )}
                    </TableData>
                    <TableData>{v.author}</TableData>
                    <TableData>
                      <div
                        className="table-action-btn"
                        data-pos={i}
                        onClick={(e) => {
                          handleClickInfo(e, setState, state);
                        }}
                      >
                        <div className="icon" data-pos={i}>
                          <ion-icon
                            name="ellipsis-vertical"
                            data-pos={i}
                          ></ion-icon>
                        </div>
                      </div>
                    </TableData>
                  </TableRaw>
                );
              })}
            </TableBody>
          </Table>
        </DataTableBody>

        <DataTableFooter>
          <div className="d-flex justify-content-center align-items-center p-3">
            <div className="custom-pagination">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={invoiceStore.invoiceData.current_page}
                itemsCountPerPage={invoiceStore.invoiceData.per_page}
                totalItemsCount={invoiceStore.invoiceData.total}
                onChange={(page) => {
                  readInvoice(dispatch, page, state.param);
                }}
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          </div>
        </DataTableFooter>
      </DataTable>

      <InvoiceInfo
        show={state.show}
        pos={state.info}
        setState={setState}
        state={state}
      />
    </>
  );
}

function handleClickInfo(e, setState, state) {
  const pos = e.target.dataset.pos;
  setState(
    Object.assign({}, state, {
      show: true,
      info: pos,
    })
  );
  console.log(pos);
}

export default Invoice;
