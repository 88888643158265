import axios from 'axios';
import api from '../../../../constant/api';
import type from '../../../../constant/type';

function getNewInvoiceNo(dispatch) {
  axios.get(api.getLastInvoiceNo).then((res) => {
    if (res.data.status) {
      dispatch({
        type: type.STORE_INVOICE_DATA,
        payload: {
          key: 'newInvoiceNo',
          data: {
            no: res.data.invoiceNo
          }
        }
      });
    }
  });
}
export { getNewInvoiceNo };
