import type from "../../../../constant/type";

function setFIndCustomerData(e, dispatch, invoice) {
  const pos = e.target.dataset.key;
  const find = invoice.findedCustomer.data[pos];
  if (find) {
    dispatch({
      type: type.SET_DEFAULT_INVOICE_CUSTOMER,
      payload: {
        data: {
          name: find.customer_name === null ? "" : find.customer_name,
          company: find.company === null ? "" : find.company,
          address: find.address === null ? "" : find.address,
          short: find.short_code,
          position: find.position === null ? "" : find.position,
          contact: find.contact1 === null ? "" : "0" + find.contact1,
        },
      },
    });
    dispatch({
      type: type.STORE_FIND_CUSTOMER,
      payload: {
        show: false,
      },
    });
  }
}

export { setFIndCustomerData };
