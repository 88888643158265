import React from "react";

function Result(props) {
  const { title, render, close } = props;
  return (
    <div className="result-list d-flex flex-column">
      {/*
        <div className="head">
        <div className="title">{title}</div>
        <div className="closebtn">
          <button className="btn h-100 w-100 p-0 text-white" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
        </div>
      </div>
        */}

      {render()}
    </div>
  );
}

function ResultItem(props) {
  const { text, dataKey, eve } = props;
  return (
    <div className="result-item">
      <div
        className="item-name"
        data-key={dataKey}
        onClick={eve}
        style={{ cursor: "pointer" }}
      >
        {text}
      </div>
      {/*
        <button
        className="btn item-action bg-danger"
        data-key={dataKey}
        onClick={eve}
      >
        <span data-key={dataKey}>
          <ion-icon name="add" data-key={dataKey}></ion-icon>
        </span>
      </button>
        */}
    </div>
  );
}

Result.defaultProps = {
  close: () => {},
};
export { Result, ResultItem };
