import axios from "axios";
import type from "../../../../constant/type";
import api from "../../../../constant/api";
import { getNewInvoiceNo } from "./getNewInvoiceNo";
const onClickSaveInvoice = (e, dispatch, invoice) => {
  let saveType = e.target.dataset.type;
  var {
    createInvoice: {
      invoiceDate,
      auther,
      salesRef,
      remark,
      to: { name, company, short, address, position, contact },
      payment: { method, paid },
    },
    invoiceFeild,
    finalTotal,
  } = invoice;

  console.log(invoice);

  let uploadPayload = {
    customer_details: {
      name: name ? name : "",
      company: company ? company : "",
      address: address ? address : "",
      short_code: short ? short : "",
      position: position ? position : "",
      contact: contact ? contact : "",
    },
    invoice: {
      author: auther ? auther : "unknown",
      invoice_date: invoiceDate,
      salesRef: salesRef ? salesRef : "unknown",
      remark: remark ? remark : "",
      discount: finalTotal.discount,
      net: finalTotal.net,
      total: finalTotal.total,
      vat: finalTotal.vat,
    },
    invoice_item: [],
    payment: {
      method: method,
      paid: paid,
    },
  };

  Object.keys(invoiceFeild).map((v) => {
    const { name, qty, unit, discount } = invoiceFeild[v];

    if (name && qty && unit && discount !== "") {
      let item = {
        name: name ? name : "0",
        qty: qty ? qty : 1,
        price: unit ? unit : 1,
        discount: discount ? discount : 0,
      };
      uploadPayload.invoice_item.push(item);
    }
  });

  // IF USER NO SELECT PRODUCT IT WILL NOT SAVE !
  if (uploadPayload.invoice_item.length > 0) {
    if (saveType === "new") {
      dispatch({
        type: type.STORE_INVOICE_DATA,
        payload: {
          key: "notify",
          data: {
            save: true,
            notifyKey: "new",
          },
        },
      });
    } else {
      dispatch({
        type: type.STORE_INVOICE_DATA,
        payload: {
          key: "notify",
          data: {
            save: true,
            notifyKey: "save",
          },
        },
      });
    }

    console.log(saveType);

    axios
      .post(api.saveInvoice, uploadPayload, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: type.RESET_INVOICE_DATA,
          });

          if (saveType === "new") {
            dispatch({
              type: type.STORE_INVOICE_DATA,
              payload: {
                key: "notify",
                data: {
                  save: false,
                  code: "1",
                  message: "Successfully Invoice Created.",
                  show: true,
                  notifyKey: "new",
                },
              },
            });

            getNewInvoiceNo(dispatch);
          } else {
            window.location.assign("/invoice");
          }
        }
      });
  } else {
    dispatch({
      type: type.STORE_INVOICE_DATA,
      payload: {
        key: "notify",
        data: {
          code: "0",
          message: "item is not selected.",
          show: true,
        },
      },
    });

    console.log("not select products");
  }

  /*
    successfully invoice created
    let date=new DateExtract();
    var {invoiceDate,auther,item,to:{name,company,short,address},payment:{method,paid}}=data;

    let uploadPayload={
        customer_details:{
            name:name?name:"",
            company:company?company:"",
            address:address?address:"",
            short_code:short?short:""
        },
        invoice:{
            author:auther?auther:"unknown",
            invoice_date:invoiceDate
        },
        invoice_item:[],
        payment:{
            method:method,
            paid:paid
        }


    }

    item.map((v)=>{
        const {name,qty,unit}=v.data
        let item={
            name:name?name:"0",
            qty:qty?qty:1,
            price:unit?unit:1
        }
        uploadPayload.invoice_item.push(item)
    })

    
    axios.post(api.saveInvoice,uploadPayload,{headers: {
        'Content-Type': 'application/json'
    }}).then((res)=>{
            if(res.data.status){
                notify(true);
            }
            else{
                notify(false);
            }
            console.log(res.data);
        })

    console.log(uploadPayload);
    
    
    */
};

export { onClickSaveInvoice };
