import React from "react";
import { Table } from "react-bootstrap";
import { withRouter, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DateExtract } from "../logic/DateExtract";
import rt from "../assets/img/rt.png";

function InvoiceView() {
  const { pos } = useParams();
  const invoice = useSelector((state) => state.invoice);
  const data = invoice.invoiceData.data[pos];

  let tot = 0;
  let subTot = 0;
  let discount = 0;

  let date = new DateExtract();

  let outDate = date.humanReadbleDate(data.invoice_date, "d-M-y", "/");

  tot =
    parseFloat(data.net_amount) +
    parseFloat(data.discount) -
    parseFloat(data.vat_amount);

  tot = tot
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <div className="w-100 h-100 d-flex justify-content-center bg-white">
      <div
        className="invoice m-0 d-flex flex-column h-100 bg-white"
        style={{ minHeight: 1000, width: 700 }}
      >
        <div className="invoice-content">
          <div className="invoice-header w-100">
            <img src={rt} alt="royaltech" />
            <span>COMPANY REGISTRATION NUMBER : PV00230320</span>
          </div>

          <div className="left-card w-50">
            <div className="company-address text-capitalize">
              <span>No. 105/33 Unity Square Sports Complex,</span>
              <span>Kalmunai. P.O: 32300,</span>
              <span>067 4335411,077 1377709</span>
              <span className="text-lowercase">
                info@royaltech.lk, www.royaltech.lk.
              </span>
            </div>

            <div className="company-to">
              <div style={{ marginBottom: 0, fontWeight: "500" }}>
                Invoice To:
              </div>
              {
                <div className="to-item-box">{`${
                  data.customer_name !== "" && data.customer_name !== "0"
                    ? data.customer_name + ","
                    : ""
                }`}</div>
              }
              {
                <div className="to-item-box">{`${
                  data.customer_company !== "" && data.customer_company !== "0"
                    ? data.customer_company + ","
                    : ""
                }`}</div>
              }
              {
                <div className="to-item-box">{`${
                  data.customer_address !== "" && data.customer_address !== "0"
                    ? data.customer_address + ","
                    : ""
                }`}</div>
              }
            </div>
          </div>

          <div className="right-card">
            <div className="invoice-badge">
              <div className="invoice-badge-box">
                <span style={{ marginRight: -4 }}>Invoice</span>
              </div>
            </div>

            <div
              className="invoice-date d-flex flex-column align-items-end"
              style={{ marginBottom: 10 }}
            >
              <span className="text-capitalize" style={{ fontWeight: 500 }}>
                Date
              </span>
              <span style={{ fontWeight: 500, fontSize: 17 }}>{outDate}</span>
            </div>

            <div className="invoice-number">
              <span className="text-capitalize" style={{ fontWeight: 500 }}>
                invoice number
              </span>
              <span style={{ fontWeight: 500, fontSize: 17 }}>
                RTK {data.invoice_no}
              </span>
            </div>
          </div>

          <div className="invoice-table">
            <Table bordered>
              <thead>
                <tr>
                  <th>PRODUCT</th>
                  <th>QTY</th>
                  <th>UNIT RATE</th>
                  <th>LINE TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {data.invoice_items.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-uppercase" style={{ width: "47%" }}>
                        {v.item_name}
                      </td>
                      <td className="text-uppercase" style={{ width: "8%" }}>
                        {v.qty}
                      </td>
                      <td className="text-uppercase" style={{ width: "17%" }}>
                        {parseFloat(v.price)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td style={{ width: "18%" }}>
                        {parseFloat(v.gross_amount)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td rowSpan={4} colSpan={2}>
                    <div className="w-100 d-flex flex-column">
                      <div
                        className="w-100 d-flex"
                        style={{ fontSize: 15, fontWeight: "bold" }}
                      >
                        Remark
                      </div>
                      <div
                        className="w-100 d-flex"
                        style={{ textAlign: "start" }}
                      >
                        <span className="mt-1">
                          {data.remark === "0" || data.remark === ""
                            ? ""
                            : data.remark}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td style={{ textAlign: "end" }}>TOTAL</td>
                  <td>{tot}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "end" }}>VAT</td>
                  <td>{data.vat_amount}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "end" }}>DISCOUNT</td>
                  <td>{data.discount}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    NET AMOUNT
                  </td>
                  <td>{data.net_amount}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="invoice-message d-flex flex-column w-100">
            <span style={{ fontWeight: 500, fontSize: 13 }}>
              Invoiced materials are not subject to refundable.
              <br />
              Please bring original invoice along with delivery note for
              warranty claim.
              <br />
              If you have any questions concerning this invoice, kindly contact
              undersigned.
            </span>
          </div>
          <div className="invoice-message d-flex flex-column w-100 mt-2">
            <span style={{ fontWeight: 500, fontSize: 15 }}>
              {`Payment method  :-  ${data.payment_method}`}
            </span>
          </div>

          <div
            className=" d-flex justify-content-center w-100"
            style={{ fontWeight: 500, fontSize: 13, marginTop: 15 }}
          >
            <span>Thanks you for your business!</span>
          </div>

          <div className="end-declaration">
            <div className="company-accountant">
              <span className="company-account-signature">
                Authorized Signature
              </span>
            </div>
          </div>
        </div>

        <div className="invoice-footer bg-light mt-auto">
          <span style={{ fontSize: 11 }}>
            Expert in software development, web development, mobile application
            development ,enterprise IT support , IT material supply
          </span>
        </div>
      </div>
    </div>
  );
}

export default InvoiceView;
