import React from "react";
import DataTable from '../components/DataTable';


class Home extends React.Component{
    render(){
        return(
            <div className="w-100 h-100 p-2">
                <DataTable/>
            </div>
        )
    }
}

export default Home;