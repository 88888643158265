import { combineReducers } from "redux";
import { counter } from "./counter";
import { auth } from "./auth";
import { layout } from "./layout";
import { invoiceStore } from "./invoiceStore";
import { invoice } from "./invoice/invoice";
import { category } from "./category/category";
import { product } from "./product/product";
import { customer } from "./customer/customer";
//import { layout } from './layout';
const root = combineReducers({
  counter: counter,
  auth: auth,
  layout: layout,
  invoice: invoice,
  category: category,
  product: product,
  customer: customer,
});

export default root;
