import React from "react";
import type from "../constant/type";

class NewAlert extends React.Component {
  handleClose(e) {
    const { dispatch, type, data } = this.props;

    dispatch({
      type: type,
      payload: {
        data: data,
      },
    });
  }
  render() {
    const { msg, status, show } = this.props;
    //const { show } = this.state;

    return (
      <div
        className={`alert app-alert ${status === "1" ? "ok" : "error"}  ${
          !show ? "d-none" : ""
        }`}
      >
        <span>{msg}</span>
        <button
          className={` btn alert-close-btn ${
            status === "1" ? "btn-success" : "btn-danger"
          } `}
          onClick={(e) => {
            this.handleClose(e);
          }}
        >
          <ion-icon name="close"></ion-icon>
        </button>
      </div>
    );
  }
}
export default NewAlert;
