import { CalculateLineTotal } from "../../../../logic/CalculateLineTotal";
import { CalculateNetAmount } from "../../../../logic/CalculateNetAmount";
import { CalculateInvoiceDiscount } from "../../../../logic/CalculateInvoiceDiscount";

const storeTo = (state, payload) => {
  const {
    data: { name, value },
  } = payload;

  let key = "";

  switch (name) {
    case "name":
      key = "name";
      break;
    case "company":
      key = "company";
      break;
    case "place":
      key = "address";
      break;
    case "short":
      key = "short";
      break;
    case "position":
      key = "position";
      break;
    case "contact":
      key = "contact";
      break;
    default:
      break;
  }

  if (key) {
    return Object.assign({}, state, {
      createInvoice: {
        ...state.createInvoice,
        to: {
          ...state.createInvoice.to,
          [key]: value,
        },
      },
    });
  } else {
    return state;
  }
};

const storeItem = (state, payload) => {
  const {
    data: { name, value, key },
  } = payload;

  const prevQty =
    state.invoiceFeild[key].qty === "" || state.invoiceFeild[key].qty === 0
      ? 1
      : state.invoiceFeild[key].qty;
  const prevUnit =
    state.invoiceFeild[key].unit === "" ? 0 : state.invoiceFeild[key].unit;
  const prevDiscount =
    state.invoiceFeild[key].discount === ""
      ? 0
      : state.invoiceFeild[key].discount;

  console.log(prevQty);
  console.log(prevUnit);
  console.log(prevDiscount);

  let targetChangeObjectName = "";
  let lineTotal = false;

  switch (name) {
    case "name":
      targetChangeObjectName = "name";
      lineTotal = false;
      break;
    case "qty":
      targetChangeObjectName = "qty";
      lineTotal = CalculateLineTotal(value, prevUnit, prevDiscount);
      break;
    case "unit":
      targetChangeObjectName = "unit";
      lineTotal = CalculateLineTotal(prevQty, value, prevDiscount);
      break;
    case "discount":
      targetChangeObjectName = "discount";
      lineTotal = CalculateLineTotal(prevQty, prevUnit, value);
      break;
    default:
      break;
  }

  return Object.assign({}, state, {
    invoiceFeild: {
      ...state.invoiceFeild,
      [key]: {
        ...state.invoiceFeild[key],
        [targetChangeObjectName]: value,
        lineTotal:
          lineTotal === false ? state.invoiceFeild[key].lineTotal : lineTotal,
      },
    },
  });
};

const storePayment = (state, payload) => {
  const {
    data: { name, value },
  } = payload;

  let targetChangeObjectName = "";
  let changedvalue = "";

  if (name === "paid") {
    if (state.createInvoice.payment.paid === "0") {
      changedvalue = "1";
    } else {
      changedvalue = "0";
    }

    targetChangeObjectName = "paid";
  } else if (name === "credit" || name === "cash" || name === "card") {
    targetChangeObjectName = "method";
    changedvalue = value;
  }

  return Object.assign({}, state, {
    createInvoice: {
      ...state.createInvoice,
      payment: {
        ...state.createInvoice.payment,
        [targetChangeObjectName]: changedvalue,
      },
    },
  });
};

const storeAuther = (state, payload) => {
  const {
    data: { value },
  } = payload;

  return Object.assign({}, state, {
    createInvoice: {
      ...state.createInvoice,
      auther: value,
    },
  });
};

const storeSalesRef = (state, payload) => {
  const {
    data: { value },
  } = payload;

  return Object.assign({}, state, {
    createInvoice: {
      ...state.createInvoice,
      salesRef: value,
    },
  });
};

const storeRemark = (state, payload) => {
  const {
    data: { value },
  } = payload;

  return Object.assign({}, state, {
    createInvoice: {
      ...state.createInvoice,
      remark: value,
    },
  });
};

const storeDate = (state, payload) => {
  const {
    data: { value },
  } = payload;

  return Object.assign({}, state, {
    createInvoice: {
      ...state.createInvoice,
      invoiceDate: value,
    },
  });
};

const changeSaveStatus = (state, payload) => {
  let changedvalue = false;

  if (state.isSave === false) {
    changedvalue = true;
  } else {
    changedvalue = false;
  }

  return Object.assign({}, state, {
    isSave: changedvalue,
  });
};

const notify = (state, payload) => {
  const { data } = payload;
  let code = null;
  let message = null;
  let save = null;
  let show = null;
  let notifyKey = null;

  if ("code" in data) {
    code = data.code;
  }
  if ("message" in data) {
    message = data.message;
  }
  if ("save" in data) {
    save = data.save;
  }
  if ("show" in data) {
    show = data.show;
  }
  if ("notifyKey" in data) {
    notifyKey = data.notifyKey;
  }

  let final = {
    code: code === null ? state.notify.code : code,
    message: message === null ? state.notify.message : message,
    save: state.notify.save,
    new: state.notify.new,
    show: show === null ? state.notify.show : show,
  };

  if (notifyKey !== null) {
    final[notifyKey] = save === null ? state.notify[notifyKey] : save;
  }

  return Object.assign({}, state, {
    notify: final,
  });
};

const resetInvoice = (state) => {
  return Object.assign({}, state, {
    createInvoice: {
      to: {
        name: "",
        company: "",
        address: "",
        short: "",
        position: "",
        contact: "",
      },
      auther: sessionStorage.getItem("name"),
      salesRef: "",
      remark: "",
      invoiceDate: new Date().toISOString().substring(0, 10),
      inoiceNo: "",
      item: [],
      payment: {
        method: "credit",
        paid: "0",
      },
      newInvoiceNo: 0,
    },
    invoiceFeild: {
      key1: {
        name: "",
        qty: "",
        unit: "",
        discount: "",
        lineTotal: "",
        suggest: false,
      },
      key2: {
        name: "",
        qty: "",
        unit: "",
        discount: "",
        lineTotal: "",
        suggest: false,
      },
      key3: {
        name: "",
        qty: "",
        unit: "",
        discount: "",
        lineTotal: "",
        suggest: false,
      },
      key4: {
        name: "",
        qty: "",
        unit: "",
        discount: "",
        lineTotal: "",
        suggest: false,
      },
      key5: {
        name: "",
        qty: "",
        unit: "",
        discount: "",
        lineTotal: "",
        suggest: false,
      },
    },
    isSave: false,
    notify: {
      code: "1",
      message: "",
      save: false,
      show: false,
    },
    invoiceData: {
      data: [],
    },

    findedCustomer: {
      data: [],
      show: false,
    },
    findedProduct: {
      data: [],
    },
    finalTotal: {
      total: 0,
      net: 0,
      vat: 0,
      discount: 0,
      discountType: "%",
      disPercentage: 0,
    },
  });
};

const storeNewInvoiceNo = (state, payload) => {
  const {
    data: { no },
  } = payload;
  return Object.assign({}, state, {
    createInvoice: {
      ...state.createInvoice,
      newInvoiceNo: no,
    },
  });
};
const ChangeFinalAmountValue = (state, payload) => {
  const { name, value } = payload.data;
  const { net, total, discount, vat } = state.finalTotal;
  let changedKey = "";
  let prewNet = net;
  let prewTotal = total;
  let prewDiscount = discount;
  let prewVat = vat;

  switch (name) {
    case "vat":
      changedKey = "vat";
      prewNet = CalculateNetAmount(prewTotal, value, prewDiscount);
      break;
    case "disPercentage":
      changedKey = "disPercentage";
      prewDiscount = CalculateInvoiceDiscount(value, prewTotal);
      prewNet = CalculateNetAmount(prewTotal, prewVat, prewDiscount);
      break;
    case "discount":
      changedKey = "discount";
      prewNet = CalculateNetAmount(prewTotal, prewVat, value);
      break;
    default:
      break;
  }

  if (changedKey !== "") {
    if (name === "disPercentage") {
      return Object.assign({}, state, {
        finalTotal: {
          ...state.finalTotal,
          [changedKey]: value,
          net: prewNet,
          discount: prewDiscount,
        },
      });
    } else {
      return Object.assign({}, state, {
        finalTotal: {
          ...state.finalTotal,
          [changedKey]: value,
          net: prewNet,
        },
      });
    }
  } else {
    return state;
  }
};
export {
  storeTo,
  storeItem,
  storePayment,
  storeAuther,
  storeDate,
  changeSaveStatus,
  notify,
  resetInvoice,
  storeNewInvoiceNo,
  ChangeFinalAmountValue,
  storeSalesRef,
  storeRemark,
};
