import type from '../../../../constant/type';

const onClickInvoicePayment = (e, dispatch) => {
  const name = e.target.dataset.name;
  const value = e.target.value;

  switch (name) {
    case 'paid':
      console.log(value);
      break;
    case 'credit':
      console.log(value);
      break;
    case 'cash':
      console.log(value);
      break;
    default:
      break;
  }

  dispatch({
    type: type.STORE_INVOICE_DATA,
    payload: {
      key: 'payment',
      data: {
        name: name,
        value: value
      }
    }
  });
};

export { onClickInvoicePayment };
