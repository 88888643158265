import type from "../../../../constant/type";

const onKeyUpInvoiceTo = (e, dispatch) => {
  let name = e.target.dataset.name;
  let currentValue = e.target.value ? e.target.value : "";

  //STORE DATA ON GLOBAL STATE
  dispatch({
    type: type.STORE_INVOICE_DATA,
    payload: {
      key: "to",
      data: {
        name: name,
        value: currentValue,
      },
    },
  });
};

export { onKeyUpInvoiceTo };
