import type from "../../../../constant/type";

const onChangeSalesRef = (e, dispatch) => {
  let value = e.target.value ? e.target.value : "";
  dispatch({
    type: type.STORE_INVOICE_DATA,
    payload: {
      key: "salesRef",
      data: {
        value: value,
      },
    },
  });
};

export { onChangeSalesRef };
