import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Private = (props) => {
  const Ele = props.ele;
  const authStore = useSelector((state) => state.auth);
  console.log(authStore);
  return authStore.auth.isOk ? <Ele /> : <Navigate to="/login" />;
};

export default Private;
