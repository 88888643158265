import PopUp from "../../components/PopUp";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import type from "../../constant/type";
import axios from "axios";
import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData,
} from "../../components/DataTableNew.js";
import paidImg from "../../assets/img/paid.png";
import Number from "../../logic/Number";
import { DateExtract } from "../../logic/DateExtract";
import api from "../../constant/api";

const closePopup = (setState, state) => {
  setState(
    Object.assign({}, state, {
      show: false,
      info: false,
    })
  );
};

function handleClickPaid(invNo, pos, dispatch) {
  axios.post(api.changePaymentStatus, { invoice_no: invNo }).then((res) => {
    if (res.data.status) {
      const chnageedStatus = res.data.paid;
      let findIndex = pos;
      console.log(pos);

      dispatch({
        type: type.CHANGE_PAYMENT_STATUS,
        payload: {
          index: findIndex,
          paid: chnageedStatus,
        },
      });
    }
  });
}

function handleDeleteInvoice(invNo, pos, dispatch, setState, state) {
  axios.post(api.deleteInvoice, { invoice_no: invNo }).then((res) => {
    if (res.data.status) {
      dispatch({
        type: type.DELETE_INVOICE,
        payload: {
          invoice_no: invNo,
        },
      });

      closePopup(setState, state);
    }
  });
}

function InvoiceInfo(props) {
  const { show, pos, setState, state } = props;
  console.log(pos);
  const invoiceStore = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  var info = invoiceStore.invoiceData.data[pos];
  if (!show) {
    info = {
      customer_name: "",
      customer_company: "",
      customer_address: "",
      invoice_date: "",
      invoice_no: "",
      net_amount: "",
      discount: "",
      author: "",
      payment_status: "",
      invoice_items: [],
    };
  }
  console.log(info);

  const date = new DateExtract();

  let tot =
    parseFloat(info.net_amount) +
    parseFloat(info.discount) -
    parseFloat(info.vat_amount);

  tot = tot
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <PopUp
      show={show}
      close={(e) => {
        closePopup(setState, state);
      }}
      title="Invoice details"
    >
      <div className="invoice-info-wrapper">
        <div className="invoice-info-head">
          <div className="invoice-cutomer-info">
            <div className="invoice-cutomer-info-item">
              {info.customer_name !== "" && info.customer_name !== "0"
                ? info.customer_name
                : "unknown"}
            </div>
            <div className="invoice-cutomer-info-item">
              {info.customer_company !== "" && info.customer_company !== "0"
                ? info.customer_company
                : "unknown"}
            </div>
            <div className="invoice-cutomer-info-item">
              {info.customer_address !== "" && info.customer_address !== "0"
                ? info.customer_address
                : "unknown"}
            </div>
          </div>
          <div className="invoice-info-date-id">
            <div className="invoice-info-date-id-item">
              {info.invoice_date
                ? date.humanReadbleDate(info.invoice_date, "d-M-y", "/")
                : "00/00/0000"}
            </div>
            <div className="invoice-info-date-id-item">
              {info.invoice_no ? "#" + info.invoice_no : "#0000"}
            </div>
          </div>
        </div>

        <div className="invoice-info-body">
          <DataTable>
            <DataTableBody>
              <Table>
                <TableHead head="Item,Qty,Rate,Amount" />
                <TableBody>
                  {info.invoice_items.map((v, i) => {
                    return (
                      <TableRaw key={i}>
                        <TableData>{v.item_name}</TableData>
                        <TableData>{v.qty}</TableData>
                        <TableData>
                          {" "}
                          {Number.thousandSeprater(v.price)}
                        </TableData>
                        <TableData>
                          {Number.thousandSeprater(v.gross_amount)}
                        </TableData>
                      </TableRaw>
                    );
                  })}
                </TableBody>
              </Table>
            </DataTableBody>
          </DataTable>

          <div className="w-100 d-flex justify-content-end mt-2">
            <div className="d-flex flex-column">
              <div className="invoice-item-amount">
                <span>TOTAL</span>
                <span>{tot}</span>
              </div>
              <div className="invoice-item-amount">
                <span>VAT</span>
                <span>
                  {info.vat_amount
                    ? Number.thousandSeprater(info.vat_amount)
                    : "00.00"}
                </span>
              </div>
              <div className="invoice-item-amount">
                <span>DISCOUNT</span>
                <span>{info.discount ? info.discount : "00.00"}</span>
              </div>
              <div className="invoice-item-amount">
                <span>NET AMOUNT</span>
                <span>
                  {info.net_amount
                    ? Number.thousandSeprater(info.net_amount)
                    : "00.00"}
                </span>
              </div>
            </div>
          </div>

          <div className="w-100 d-flex mt-2">
            <div className="invoice-auther-info">
              <span>Created by :</span>
              <span>{info.author ? info.author : "Unknown"}</span>
            </div>
          </div>
        </div>

        <div className="invoice-info-footer">
          <div className="w-50 d-flex ">
            {info.payment_status === "0" ? (
              <button
                type="button"
                className="btn btn-success m-1"
                onClick={(e) => {
                  handleClickPaid(info.invoice_no, pos, dispatch);
                  console.log(pos);
                }}
              >
                Paid
              </button>
            ) : (
              <img src={paidImg} alt="paid" className="paid-image" />
            )}
          </div>
          <div className="w-25 d-flex justify-content-center">
            <Link to={`/invoice/preview/${pos}`} className="btn btn-light m-1">
              Preview
            </Link>
          </div>
          <div className="w-25 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-danger m-1"
              onClick={(e) => {
                handleDeleteInvoice(
                  info.invoice_no,
                  pos,
                  dispatch,
                  setState,
                  state
                );
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </PopUp>
  );
}

export default InvoiceInfo;
