export class DateExtract{
    humanReadbleDate(timestamp,format='d-m-y',type="-"){
        const dmy=/(d)-(m)-(y)/g;
        const dMy=/(d)-(M)-(y)/g;
        const dMytime=/(h):(m)/g;
        const ymd=/(y)-(m)-(d)/g;
        const mdy=/(m)-(d)-(y)/g;
        const date = new Date(timestamp)
        
        const monthsLong=["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
      

        const datevalues = {
            year:date.getFullYear(),
            month:date.getMonth(),
            day:date.getDate(),
            hour:date.getHours(),
            minit:date.getMinutes(),
        }

        //preset values
        const sm=date.toLocaleString('default',{ month: 'short' });
        const lm=date.toLocaleString('default',{ month: 'long' });
        const y=datevalues.year;
        const d=datevalues.day;
        const m=datevalues.month;
        const hm=date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        const minit=datevalues.minit;

        if(dmy.test(format)){
            if(type=="-"){
                return d+"-"+m+"-"+y;
               
            }
            else if(type=="/"){
                return d+"/"+m+"/"+y;
            }
            
        }
        else if(dMy.test(format)){
            if(type=="-"){
                return d+"-"+sm+"-"+y;
            }
            else if(type=="/"){
                return d+"/"+sm+"/"+y;
            }
            
        }
        else if(dMytime.test(format)){
            return d+"-"+sm+"-"+y+" "+hm;
        }
        else if(ymd.test(format)){
            return y+"-"+m+"-"+d;
        }
        else if(mdy.test(format)){
            return m+"-"+d+"-"+y;
        }
        
        return datevalues;
    }

    
}