const type = {
  RESIZE_LEFT_PANEL: "RESIZE_LEFT_PANEL",
  SAVE_INVOICE_DATA: "SAVE_INVOICE_DATA",
  SAVE_INVOICE_INFO: "SAVE_INVOICE_INFO",
  GET_INVOICE_INFO: "GET_INVOICE_INFO",
  CHANGE_PAYMENT_STATUS: "CHANGE_PAYMENT_STATUS",
  DELETE_INVOICE: "DELETE_INVOICE",
  STORE_CUSTOMER_DATA: "STORE_CUSTOMER_DATA",
  STORE_INVOICE_DATE: "STORE_INVOICE_DATE",
  STORE_AUTHER_DATA: "STORE_AUTHER_DATA",
  STORE_INVOICE_ITEM: "STORE_INVOICE_ITEM",
  SAVE_INVOICE: "SAVE_INVOICE",
  LOG_IN: "LOG_IN",
  LOG_OUT: "LOG_OUT",
  STORE_INVOICE_DATA: "STORE_INVOICE_DATA",
  RESET_INVOICE_DATA: "RESET_INVOICE_DATA",
  STORE_MAIN_CATEGORY: "STORE_MAIN_CATEGORY",
  STORE_SUB_CATEGORY: "STORE_SUB_CATEGORY",
  STORE_PRODUCT: "STORE_PRODUCT",
  SHOW_AND_CLOSE_POPUP: "SHOW_AND_CLOSE_POPUP",
  PRODUCT_NOTIFY: "PRODUCT_NOTIFY",
  STORE_CUSTOMER: "STORE_CUSTOMER",
  SHOW_AND_CLOSE_CUSTOMER_POPUP: "SHOW_AND_CLOSE_CUSTOMER_POPUP",
  CUSTOMER_NOTIFY: "CUSTOMER_NOTIFY",
  ADD_NEW_INVOICE_ITEM: "ADD_NEW_INVOICE_ITEM",
  REMOVE_INVOICE_ITEM: "REMOVE_INVOICE_ITEM",
  STORE_FIND_CUSTOMER: "STORE_FIND_CUSTOMER",
  SET_DEFAULT_INVOICE_CUSTOMER: "SET_DEFAULT_INVOICE_CUSTOMER",
  STORE_FIND_PRODUCT: "STORE_FIND_PRODUCT",
  SET_DEFAULT_INVOICE_PRODUCT: "SET_DEFAULT_INVOICE_PRODUCT",
  SHOW_SUGGEST: "SHOW_SUGGEST",
  CALCULATE_INVOICE_TOTAL: "CALCULATE_INVOICE_TOTAL",
  CHANAGE_FINAL_AMOUNT_VALUE: "CHANAGE_FINAL_AMOUNT_VALUE",
  STORE_CUSTOMER_CODE: "STORE_CUSTOMER_CODE",
};
export default type;
