import type from "../../../constant/type";
import { ChangeState } from "../../../logic/ChangeState";
const store = {
  data: {
    current_page: "",
    per_page: "",
    total: "",
    data: [],
  },
  createCustomer: {
    popup: false,
    notify: {
      message: "",
      messageShow: false,
      code: "0",
    },
  },
  short_code: [],
};

const customer = (state = store, action) => {
  const {
    STORE_CUSTOMER,
    SHOW_AND_CLOSE_CUSTOMER_POPUP,
    CUSTOMER_NOTIFY,
    STORE_CUSTOMER_CODE,
  } = type;

  switch (action.type) {
    case STORE_CUSTOMER:
      return Object.assign({}, state, {
        data: action.payload.data,
      });
    case SHOW_AND_CLOSE_CUSTOMER_POPUP:
      return Object.assign({}, state, {
        createCustomer: {
          ...state.createCustomer,
          popup: state.createCustomer.popup === false ? true : false,
        },
      });
    case CUSTOMER_NOTIFY:
      return Object.assign({}, state, {
        createCustomer: {
          ...state.createCustomer,
          notify: action.payload.data,
        },
      });
    case STORE_CUSTOMER_CODE: {
      return ChangeState(state, {
        short_code: action.payload.data,
      });
    }

    default:
      return state;
  }
};

export { customer };
