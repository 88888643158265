import React from 'react';
import { Outlet } from 'react-router-dom';

//Component
import SideBar from './SideBar';
import TopBar from './TopBar';
import Content from './Content';

class AppLayout extends React.Component {
  render() {
    return (
      <div className="app">
        <TopBar />
        <SideBar />
        <Content>
          <Outlet />
        </Content>
      </div>
    );
  }
}

export default AppLayout;
