import {
  DataTable,
  DataTableHead,
  DataTableFooter,
  DataTableBody,
  Table,
  TableHead,
  TableBody,
  TableRaw,
  TableData,
} from "../../components/DataTableNew";
import Alert from "../../components/Alert";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import axios from "axios";
import api from "../../constant/api";
import { useSelector } from "react-redux";
import type from "../../constant/type";
import NewAlert from "../../components/NewAlert";
function CreateProduct(props) {
  const defaultState = {
    formData: {
      name: "",
      qty: "",
      bufferStock: "",
      vat: "",
      discount: "",
      constA: "",
      leastA: "",
      sellingA: "",
      printedA: "",
      category: "",
      subCategory: "",
      productId: "",
      barcodeNo: "",
    },
    category: {
      main: [
        {
          catName: "electronic",
          catId: 10,
        },
        {
          catName: "Hardisk",
          catId: 15,
        },
      ],
      sub: [
        {
          subCatName: "low Electric",
          subCatId: 10,
          catId: 10,
        },
        {
          subCatName: "SSD Hard disk",
          subCatId: 10,
          catId: 10,
        },
      ],
    },
  };

  const [state, setState] = useState(defaultState);
  const category = useSelector((state) => state.category);
  const product = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const errorMessage = {
    number: "must be a number",
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      qty: "",
      bufferStock: "",
      vat: "",
      discount: "",
      costAmount: "",
      leastAmount: "",
      sellingAmount: "",
      printedAmount: "",
      category: "",
      subCategory: "",
      productId: "150",
      barcodeNo: "Et102",
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      qty: yup.number(),
      bufferStock: yup.number(),
      vat: yup.number(),
      discount: yup.number(),
      costAmount: yup.number(),
      leastAmount: yup.number(),
      sellingAmount: yup.number(),
      printedAmount: yup.number(),
      category: yup.number().required(),
      subCategory: yup.number().required(),
      productId: "",
      barcodeNo: "",
    }),
    onSubmit: (formData) => {
      saveProductData(formData, dispatch);
    },
  });

  return (
    <DataTable>
      <DataTableBody>
        <div class="card  bg-transparent border-0">
          <div class="card-body">
            <div class="card-form">
              <form
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                className="row"
                id="createProductForm"
              >
                <div className="col-md-12 form-row mb-3">
                  <NewAlert
                    show={product.createProduct.notify.messageShow}
                    msg={product.createProduct.notify.message}
                    status={product.createProduct.notify.code}
                    dispatch={dispatch}
                    type={type.PRODUCT_NOTIFY}
                    data={{ message: "", messageShow: false, code: "0" }}
                  />
                </div>
                <div class="col-md-6 form-row">
                  <label className="mb-2 text-white">Category</label>

                  <select
                    class="form-select rawinput mb-3 custom-input"
                    name="category"
                    value={formik.values.category}
                    onChange={(e) => {
                      formik.handleChange(e);

                      axios
                        .get(api.getSubCategory + "?cid=" + e.target.value)
                        .then((res) => {
                          if (res.data.status) {
                            dispatch({
                              type: type.STORE_SUB_CATEGORY,
                              payload: {
                                data: res.data.data,
                              },
                            });
                          }
                        });
                    }}
                  >
                    <option value="">--- Select One ---</option>
                    {category.main.map((v, i) => {
                      return <option value={v.cid}>{v.name}</option>;
                    })}
                  </select>
                  <div class="form-text mb-3 text-danger text-capitalize">
                    {formik.errors.category ? formik.errors.category : ""}
                  </div>
                </div>
                <div class="col-md-6 form-row">
                  <label className="mb-2 text-white text-capitalize">
                    Sub Category
                  </label>

                  <select
                    class="form-select rawinput mb-3 custom-input"
                    name="subCategory"
                    value={formik.values.subCategory}
                    onChange={formik.handleChange}
                  >
                    <option value="">--- Select One ---</option>
                    {category.sub.map((v, i) => {
                      return <option value={v.sub_id}>{v.name}</option>;
                    })}
                  </select>
                  <div class="form-text mb-3 text-danger text-capitalize">
                    {formik.errors.subCategory ? formik.errors.subCategory : ""}
                  </div>
                </div>
                <div class="col-md-6 form-row">
                  <TextItem
                    id="x"
                    name="productId"
                    label="Product ID"
                    val={formik.values.productId}
                    readOnly={true}
                  />
                </div>

                <div class="col-md-6 form-row">
                  <TextItem
                    id="x"
                    name="barcodeNo"
                    label="Barcode No"
                    val={formik.values.barcodeNo}
                    readOnly={true}
                  />
                </div>
                <div class="col-md-12 form-row">
                  <TextItem
                    id="x"
                    name="name"
                    label="Product Name"
                    val={formik.values.name}
                    eve={formik.handleChange}
                    error={formik.errors.name}
                  />
                </div>
                <div class="col-md-6 form-row">
                  <TextItem
                    id="x"
                    name="qty"
                    label="Quantity"
                    val={formik.values.qty}
                    eve={formik.handleChange}
                    error={formik.errors.qty}
                  />
                </div>
                <div class="col-md-6 form-row">
                  <TextItem
                    id="x"
                    name="bufferStock"
                    label="Buffer Stock"
                    val={formik.values.bufferStock}
                    eve={formik.handleChange}
                    error={formik.errors.bufferStock}
                  />
                </div>

                <div class="col-md-6 form-row">
                  <TextItem
                    id="x"
                    name="vat"
                    label="Vat"
                    val={formik.values.vat}
                    eve={formik.handleChange}
                    error={formik.errors.vat}
                  />
                </div>

                <div class="col-md-6 form-row">
                  <TextItem
                    id="x"
                    name="discount"
                    label="Discount"
                    val={formik.values.discount}
                    eve={formik.handleChange}
                    error={formik.errors.discount}
                  />
                </div>

                <div class="col-md-3 form-row">
                  <TextItem
                    id="x"
                    name="costAmount"
                    label="Cost Amount"
                    val={formik.values.costAmount}
                    eve={formik.handleChange}
                    error={formik.errors.costAmount}
                  />
                </div>
                <div class="col-md-3 form-row">
                  <TextItem
                    id="x"
                    name="leastAmount"
                    label="Least Amount"
                    val={formik.values.leastAmount}
                    eve={formik.handleChange}
                    error={formik.errors.leastAmount}
                  />
                </div>
                <div class="col-md-3 form-row">
                  <TextItem
                    id="x"
                    name="sellingAmount"
                    label="Selling Amount"
                    val={formik.values.sellingAmount}
                    eve={formik.handleChange}
                    error={formik.errors.sellingAmount}
                  />
                </div>
                <div class="col-md-3 form-row">
                  <TextItem
                    id="x"
                    name="printedAmount"
                    label="Printed Amount"
                    val={formik.values.printedAmount}
                    eve={formik.handleChange}
                    error={formik.errors.printedAmount}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </DataTableBody>

      <DataTableFooter>
        <div className="card-body">
          <div className="d-flex justify-content-between m-3">
            <button
              className="btn btn-primary invoice-action-btn m-0"
              type="submit"
              form="createProductForm"
            >
              <span>New</span>
            </button>
            <button
              className="btn btn-success invoice-action-btn m-0"
              type="submit"
              form="createProductForm"
            >
              <span>Save</span>
            </button>
          </div>
        </div>
      </DataTableFooter>
    </DataTable>
  );
}

const TextItem = (props) => {
  const { label, name, id, val, eve, error, readOnly } = props;
  return (
    <>
      <label className="mb-2 text-white">{label}</label>
      <input
        type="text"
        name={name}
        data-name={name}
        value={val}
        class="form-control rawinput mb-1 custom-input"
        onChange={eve}
        readOnly={readOnly}
      />

      <div class="form-text mb-3 text-danger text-capitalize">
        {error ? error : ""}
      </div>
    </>
  );
};

TextItem.defaultProps = {
  readOnly: false,
};

const SelectItem = (props) => {
  const { data, label } = props;
  return (
    <>
      <label className="mb-2 text-white">{label}</label>
      <select class="form-select rawinput mb-3 custom-input">
        {data.map((v, i) => {
          return <option value={v}>{v}</option>;
        })}
      </select>
    </>
  );
};

function manageFormData(e, setState, state) {
  const key = e.target.dataset.name;
  const value = e.target.value;
  setState(setValueToFormData(key, value, state));
  console.log(state);
}

function setValueToFormData(key, value, state) {
  return Object.assign({}, state, {
    formData: {
      ...state.formData,
      [key]: [value],
    },
  });
}

function saveProductData(formData, dispatch) {
  const {
    bufferStock,
    category,
    costAmount,
    discount,
    leastAmount,
    name,
    printedAmount,
    qty,
    sellingAmount,
    subCategory,
    vat,
  } = formData;

  const finalApiData = {
    bufferStock: bufferStock ? bufferStock : 0,
    category: category,
    subCategory: subCategory,
    costAmount: costAmount ? costAmount : 0,
    discount: discount ? discount : 0,
    leastAmount: leastAmount ? leastAmount : 0,
    name: name,
    printedAmount: printedAmount ? printedAmount : 0,
    qty: qty ? qty : 0,
    sellingAmount: sellingAmount ? sellingAmount : 0,
    vat: vat ? vat : 0,
  };

  axios.post(api.saveProduct, finalApiData).then((res) => {
    if (res.data.status) {
      dispatch({
        type: type.PRODUCT_NOTIFY,
        payload: {
          data: {
            message: "Successfully Product created.",
            messageShow: true,
            code: "1",
          },
        },
      });
    }
  });
}

export default CreateProduct;
