import React, { Component } from "react";
import { connect } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";

//components
import AppLayout from "./components/AppLayout";
import Private from "./components/Private";
//pages
import Home from "./page/Home";

import Store from "./page/store/Store";
import Product from "./page/product/Product";
import CreateProduct from "./page/product/CreateProduct";
import Invoice from "./page/invoice/Invoice";
import InvoiceView from "./page/InvoiceView";
import Login from "./page/Login";
import Customer from "./page/customer/Customer";
import CreateInvoice from "./page/CreateInvoice";
import Category from "./page/category/Category";
//logics

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Private ele={Test} />} />

            <Route path="product">
              <Route index element={<Private ele={Product} />} />
              <Route path="create" element={<Private ele={CreateProduct} />} />
            </Route>

            <Route path="invoice">
              <Route index element={<Private ele={Invoice} />} />
              <Route path="create" element={<Private ele={CreateInvoice} />} />
            </Route>

            <Route path="customers">
              <Route index element={<Private ele={Customer} />} />
            </Route>

            <Route path="/category" element={<Private ele={Category} />} />

            <Route path="cars" element={<Private ele={Test} />} />
            <Route path="settings" element={<Private ele={Test} />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/invoice/preview/:pos" element={<InvoiceView />} />
          <Route path="*" element={<span>404</span>} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const Test = () => {
  return <div className="text-white">Under Construction</div>;
};
const Work = () => {
  return <div className="text-white">Work</div>;
};
const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return { dispatch: dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
